import { Skeleton } from '@mui/material'
import React from 'react'

const HomeSectionSkeleton = () => {
  return (
    <div className='container my-5'>
        <Skeleton variant='rounded' height={600} sx={{width:"100%"}}/>
    </div>
  )
}

export default HomeSectionSkeleton
