import React from "react";
import "../Navbar.css";
import logo from "../../../../images/logo.jpg";
import { Link, useNavigate } from "react-router-dom";
import BtnComponent from "../../../Utility/BtnComponent/BtnComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsStaggered } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../../../Utility/Sidebar/Sidebar";
import { useDispatch } from "react-redux";
import { changeActive } from "../../../../Redux/Redux-Toolkit/Slices/SidebarSlice/sidebarSlice";
import profile_img from "../../../../images/anonymous.png";
import UserProfileHook from "../../../../CustomHooks/User/UserProfileHook";
import GetAllCategoriesHook from "../../../../CustomHooks/Categories/GetAllCategoriesHook";
import FilterHook from "../../../../CustomHooks/FiltrationHooks/FilterHook";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie'
const InternalNavbar = ({
  homeActive,
  listsActive,
  aboutUsActive,
  contactUsActive,
  projectsActive
}) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(changeActive());
  };
  const [user,,data] = UserProfileHook();
  const [categories_loading,categories] = GetAllCategoriesHook();
 const [,,,,,,,,,,,,,,,onChangeType,,,,onChangeCategoryId,,,,,,,,,,,,,]= FilterHook();
  const Navigate = useNavigate()
  const handleCategoryClick =(e,val)=>{
    onChangeCategoryId(e)
    onChangeType(e,val)
    if(window.location.pathname !== "/listing-page"){
      Navigate("/listing-page")
    }else{
      return
    }
  }
  const lang = Cookies.get("i18next")
  const {t} = useTranslation()
  return (
    <nav className="internal-navbar navbar navbar-hover navbar-expand-lg navbar-soft">
      <div className="container">
        <Link to={"/"} className="navbar-brand">
          <img src={logo} loading="lazy" alt="logo" className="img-fluid" />
        </Link>
        <div className="control-btns d-flex align-items-center ">
          <div className="auth-content d-block d-sm-none">
            {typeof user === "undefined" ? (
              <div className="login-btn mx-2">
                <Link to={"/login-page"}>
                  <BtnComponent value= {t("generalSections.topBar.login")} />
                </Link>
              </div>
            ) : (
              <div className="profile-btn profile-btn-wrapper mx-2">
                <Link to="/user-profile-page">
                  <div className="img-box">
                    <img
                      className="h-100 w-100"
                      src={data?.image || profile_img}
                      alt="profile-img"
                      loading="lazy"
                    />
                  </div>
                </Link>
              </div>
            )}
          </div>
          <div
            className="toggle-btn-sidebar d-block d-lg-none"
            onClick={handleClick}
          >
            <div className="btn-box h-100 wd-fit d-flex align-items-center justify-content-center">
              <BtnComponent
                type={"button"}
                icon={<FontAwesomeIcon icon={faBarsStaggered} />}
              />
            </div>
          </div>
        </div>
        <div className="collapse navbar-collapse" id="main_nav99">
          <ul className="navbar-nav mx-auto ">
            <li className={`nav-item ${homeActive}`}>
              <Link className="nav-link" to="/" data-toggle="dropdown">
                {t("generalSections.navBar.home")}
              </Link>
            </li>
            <li className="nav-item dropdown">
              <span className="nav-link dropdown-toggle" data-toggle="dropdown">
              {t("generalSections.navBar.sale")}
              </span>
              <ul className="dropdown-menu animate fade-up">
                {
                  categories_loading===false?
                  categories?.map((item,i)=>{return(
                  <li 
                  className="dropdown-item icon-arrow"
                  key={item?.id} id={item?.id} onClick={(e)=>handleCategoryClick(e,"sale")}
                  >
                    {lang==="ar"?
                    item?.data_trans?item?.data_trans[0]?.title_ar:null
                    :
                    item?.data_trans?item?.data_trans[1]?.title_en:null
                    }
                  </li>
                  )})
                  :
                  null
                }
              </ul>
            </li>
            <li className="nav-item dropdown">
              <span className="nav-link dropdown-toggle" data-toggle="dropdown">
              {t("generalSections.navBar.rent")}
              </span>
              <ul className="dropdown-menu animate fade-up">
              {
                  categories_loading===false?
                  categories?.map((item,i)=>{return(
                  <li 
                  className="dropdown-item icon-arrow"
                  key={item?.id} id={item?.id}
                   onClick={(e)=>handleCategoryClick(e,"rent")}
                  >
                   {lang==="ar"?
                    item?.data_trans?item?.data_trans[0]?.title_ar:null
                    :
                    item?.data_trans?item?.data_trans[1]?.title_en:null
                    }
                  </li>
                  )})
                  :
                  null
                }
              </ul>
            </li>
            <li className={`nav-item ${listsActive}`}>
              <Link
                className="nav-link"
                to="/listing-page"
                data-toggle="dropdown"
              >
                {t("generalSections.navBar.list")}
              </Link>
            </li>
            <li className={`nav-item ${projectsActive}`}>
              <Link
                className="nav-link"
                to="/projects-page"
                // data-toggle="dropdown"
              >
                {t("generalSections.navBar.projects")}
              </Link>
            </li>
            <li className={`nav-item ${aboutUsActive}`}>
              <Link
                className="nav-link active"
                to="/about-us-page"
                data-toggle="dropdown"
              >
                {t("generalSections.navBar.aboutUs")}
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <Sidebar />
    </nav>
  );
};

export default InternalNavbar;
