import { useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { GetUserNotifications } from "../../Redux/Redux-Toolkit/Slices/User/UserNotificationSlice";
const UserNotificationsHook = () => {
  const [loading,setLoading] = useState(false);
  const [data,setData] = useState([]);
  const res = useSelector((state) => state.rootReducer.userNotificationReducer);
  const dispatch = useDispatch();

  let user;
  try{
    if (typeof Cookies.get("user") !== "undefined") {
      user = JSON.parse(Cookies.get("user"));
    }
  }catch(e){}

  const getData =async () => {
    setLoading(true);
    await dispatch(GetUserNotifications());
    setLoading(false);
  }
  useEffect(() => {
    getData();
  },[]);

  useEffect(() => {
    try{
      if (loading === false) {
        if(res){
          if (res?.userNotifications?.data) {
            setData(res?.userNotifications?.data);
          }
        }
      }
    }catch(e){}
  },[loading,res]);


  return [user,loading, data]
};

export default UserNotificationsHook;
