import React, { useState } from 'react'
import Card from "../../../components/Utility/Cards/Card/Card";
import HorizontalCard from "../../../components/Utility/Cards/HorizontalCard/HorizontalCard";
import CardSkeleton from '../../Utility/Cards/CardSkeleton/CardSkeleton';
import HorizontalCardSkeleton from '../../Utility/Cards/HorizontalCardSkeleton/HorizontalCardSkeleton';
import NotFound from '../../Utility/NotFound/NotFound';
import CustomPagination from '../../Utility/CustomPagination/CustomPagination';
import GetAllWishlistHook from '../../../CustomHooks/Wishlist/GetAllWishlistHook'
import { useTranslation } from 'react-i18next';
import GetAllUnitsHook from '../../../CustomHooks/UnitsHook/GetAllUnitsHook';
import ListContentNav from '../ListContentNav/ListContentNav';
import FilterHook from '../../../CustomHooks/FiltrationHooks/FilterHook';
const ListContent = () => {
  const [t] = useTranslation()
    const [active, setActive] = useState("vertical-cards");
    const [,,wishlistIds] = GetAllWishlistHook()
    const [loading,units,unitsCount,meta,,onPressPaginate] = GetAllUnitsHook()
    const [
      price,
      space,
      rooms,
      bathrooms,
      location,
      type,
      searchWord,
      property_id,
      category_id,
      minPrice,
      maxPrice,
      onChangePrice,
      onChangeSpace,
      onChangeBathrooms,
      onChangeLocation,
      onChangeType,
      onChangeSearchWord,
      onChangeRooms,
      onChangePropertyId,
      onChangeCategoryId,
      onChangeMaxPrice,
      onChangeMinPrice,
      onSearch,
      Reset,
      sort,
      onChangeSort,
      city_id,
      onChangeCityId,
      onChangeCode,
      code,
      RefreshData,
    ]= FilterHook()
   
    const handleChangeActive = (cardStyle)=>{
      setActive(cardStyle)
    }
  return (
    <div className="list-content">
      <div className="tabs__custom-v2">
      <ListContentNav changeActive={handleChangeActive} active={active}/>
        <div className={
            active === "vertical-cards"
              ? "tab-content active"
              : "tab-content"
          }
          id="myTabContent"
        >
          <div
            className="tab-pane fade show active my-4"
            id="pills-tab-two"
            role="tabpanel"
            aria-labelledby="pills-tab-two"
          >
            <div className="row units-wrapper">
              {
              loading===false? 
              unitsCount>0?
              units?.map((item) => (
                <div className="col-sm-6 col-xl-4" key={item?.id}>
                <Card item={item} key={item?.id} favItem={wishlistIds}/>
              </div>
              ))
              :<NotFound/>
              :
              <>
              <div className="col-sm-6 col-lg-4">
              <CardSkeleton />
              </div>
              <div className="col-sm-6 col-lg-4">
              <CardSkeleton />
              </div>
              <div className="col-sm-6 col-lg-4">
              <CardSkeleton />
              </div>
              <div className="col-sm-6 col-lg-4">
              <CardSkeleton />
              </div>
              <div className="col-sm-6 col-lg-4">
              <CardSkeleton />
              </div>
              <div className="col-sm-6 col-lg-4">
              <CardSkeleton />
              </div>
              <div className="col-sm-6 col-lg-4">
              <CardSkeleton />
              </div>
              <div className="col-sm-6 col-lg-4">
              <CardSkeleton />
              </div>
              <div className="col-sm-6 col-lg-4">
              <CardSkeleton />
              </div>
              <div className="col-sm-6 col-lg-4">
              <CardSkeleton />
              </div>
              <div className="col-sm-6 col-lg-4">
              <CardSkeleton />
              </div>
              <div className="col-sm-6 col-lg-4">
              <CardSkeleton />
              </div>
              </>
              }
            </div>
          </div>
        </div>
        <div className={
            active === "horizontal-cards"
              ? "tab-content active"
              : "tab-content"
          }
          id="myTabContent"
        >
          <div
            className="tab-pane fade show active my-4"
            id="pills-tab-two"
            role="tabpanel"
            aria-labelledby="pills-tab-two"
          >
            <div className="row units-wrapper">
              {
              loading===false?
              unitsCount>0?
              units?.map((item) => (
                <div className="col-12 my-2" key={item?.id}>
                  <HorizontalCard item={item} key={item?.id} favItem={wishlistIds} />
                </div>
              ))
              :<NotFound/>
              :
              <>
              <HorizontalCardSkeleton />
              <HorizontalCardSkeleton />
              <HorizontalCardSkeleton />
              <HorizontalCardSkeleton />
              <HorizontalCardSkeleton />
              <HorizontalCardSkeleton />
              <HorizontalCardSkeleton />
              <HorizontalCardSkeleton />
              <HorizontalCardSkeleton />
              <HorizontalCardSkeleton />
              <HorizontalCardSkeleton />
              <HorizontalCardSkeleton />
              </>
              }
            </div>
          </div>
        </div>
        <div className='pagination-content d-flex align-items-center justify-content-center' >
              <CustomPagination
                totalPages={meta?.last_page}
                currentPage={meta?.current_page}
                onChange={onPressPaginate}
              />
        </div>
      </div>
  </div>
  )
}

export default ListContent
