import { useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllProperties } from "../../Redux/Redux-Toolkit/Slices/Properties/PropertiesSlice";
const GetAllPropertiesHook = () => {
  const [loading,setLoading] = useState(false);
  const [properties,set_properties] = useState([]);
  const res = useSelector((state) => state.rootReducer.propertiesReducer);
  const dispatch = useDispatch();

  const getData =async () => {
    setLoading(true);
    await dispatch(GetAllProperties());
    setLoading(false);
  }
  useEffect(() => {
    if(!res?.allProperties?.data){
      getData();
    }
  },[]);

  useEffect(() => {
    try{
      if (loading === false) {
        if (res?.data?.allProperties?.data) {
          set_properties(res.data.allProperties.data);
        }
      }
    }catch(e){}
  },[loading,res]);


  return [loading,properties];
};

export default GetAllPropertiesHook;
