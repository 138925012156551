import React from "react";
import "../Share.css";
import Notify from "../../../../CustomHooks/UseNotification/UseNotification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import {FacebookIcon,FacebookMessengerIcon,FacebookMessengerShareButton,FacebookShareButton,
  LinkedinIcon,LinkedinShareButton,TelegramIcon,TelegramShareButton,TwitterIcon,
  TwitterShareButton,WhatsappIcon,WhatsappShareButton,
} from "react-share";
const ShareCard = ({shareUrl,handleChange}) => {
  const copyCurrentUrl = () => {
    // Get the current URL
    const currentUrl = window.location.href;

    // Create a temporary textarea element to hold the URL
    const tempInput = document.createElement("textarea");
    tempInput.value = currentUrl;
    document.body.appendChild(tempInput);

    // Select the text in the textarea and copy it to the clipboard
    tempInput.select();
    document.execCommand("copy");

    // Remove the temporary textarea element
    document.body.removeChild(tempInput);

    // Optionally, you can show a success message to the user
    Notify("URL copied", "success");
  };
  return (
    <div className="share-component" onClick={() => handleChange()}>
      <div className="share-wrapper">
        <div className="btns-wrapper">
          <FacebookShareButton url={shareUrl}>
            <FacebookIcon size={60} round={true} />
          </FacebookShareButton>
          <FacebookMessengerShareButton url={shareUrl}>
            <FacebookMessengerIcon size={60} round={true} />
          </FacebookMessengerShareButton>
          <TelegramShareButton url={shareUrl}>
            <TelegramIcon size={60} round={true} />
          </TelegramShareButton>
          <WhatsappShareButton url={shareUrl}>
            <WhatsappIcon size={60} round={true} />
          </WhatsappShareButton>
          <TwitterShareButton url={shareUrl}>
            <TwitterIcon size={60} round={true} />
          </TwitterShareButton>
          <LinkedinShareButton url={shareUrl}>
            <LinkedinIcon size={60} round={true} />
          </LinkedinShareButton>
          <button className="copy-btn" onClick={() => copyCurrentUrl()}>
            <FontAwesomeIcon icon={faCopy} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareCard;
