import React, { useRef, useState } from "react";
import "./SlideShow.css";
//Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import 'swiper/css/pagination';
import "swiper/css/thumbs";
import "../../../css/swiper.css"
// install Swiper modules
import { FreeMode,Pagination, Navigation, Thumbs ,Keyboard} from "swiper/modules";
import SwiperCore from 'swiper/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
// swiper slide content component
export const SwiperSlideContent = ({ img }) => {
  return (
    <div className="item h-100 swiper-slide-item ">
      <div className="sidlo slider__image__detail-large-one">
        <img
          src={img}
          alt="unit-img"
          className="img-transition"
          loading="lazy"
        />
      </div>
    </div>
  );
};
const SlideShow = ({unitDetails}) => {
  const [thumbsSwiper,setThumbsSwiper] = useState(null);
  const [active, setActive] = useState(false);
  const [t] = useTranslation()
  SwiperCore.use([Keyboard]);
// function CustomKeyboardSwiper() {
  const swiperRef = useRef(null);
  const handleKeydown = (e) => {
    if (e.key === 'ArrowLeft') {
      swiperRef.current.swiper.slidePrev();
    } else if (e.key === 'ArrowRight') {
      swiperRef.current.swiper.slideNext();
    } else if (e.key === 'Enter') {
      // Perform additional actions on Enter key press
      // console.log('Enter key pressed');
      return
    }
  };
  return (
    <div className="slide-show row">
        <div className="col-lg-6 mb-2 img-wrapper" onClick={()=>setActive(!active)}>
        <div className="row">
        {
          unitDetails?.images?.slice(0,4)?.map((item,i)=>{return(
            <div className="col-6 m-0 mb-1 p-1 img-content-item-left" key={item?.id}>
            <img src={item?.image} alt="img"/>
            </div>
          )})
        }
        </div>
      </div>
      <div className="col-lg-6 mb-1 p-1 img-wrapper img-content-item-right" onClick={()=>setActive(!active)}>
        <div className="show-all-imgs transition" onClick={()=>setActive(!active)}>{t("singleDetailsPage.slideShow.showAll")} <FontAwesomeIcon icon={faEye}/></div>
        <img src={unitDetails?.images?unitDetails?.images[5]?.image:null} alt="img"/>
      </div>
      <div className={active===true?"popup-swiper active":"popup-swiper"}>
        <div className="popup-swiper-wrapper">
          <div className="swiper-close-btn transition" onClick={()=>setActive(!active)}><FontAwesomeIcon icon={faX}/></div>
          <Swiper
        style={{
          "--swiper-pagination-bullet-width": "10px",
          "--swiper-pagination-bullet-height": "10px",
          "--swiper-pagination-bottom": "-5px",
          "--swiper-pagination-color": "#f16022",
          "--swiper-navigation-color": "#fff",
        }}
        pagination={{
          clickable: true,
        }}
        keyboard={{
          enabled: true,
          onKeydown: handleKeydown,
        }}
        loop={false}
        spaceBetween={10}
        navigation={true}
        thumbs={thumbsSwiper ? { swiper: thumbsSwiper } : undefined}
        modules={[FreeMode,Pagination, Navigation, Thumbs]}
        className="mySwiper2"
      >
        {
          unitDetails?.images?.map((item,i)=>{return(
        <SwiperSlide key={item?.id}>
          <SwiperSlideContent img={item?.image} />
        </SwiperSlide>
          )})
        }
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default SlideShow;
