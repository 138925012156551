import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {usePostDataToken } from "../../../../Hooks/PostDataHook";
import useDeleteData from "../../../../Hooks/DeleteDataHook"
import { useGetDataToken } from "../../../../Hooks/GetDataHook";
const initialState = {
    addToWishlist:[],
    deleteFromWishlist:[],
    allWishlist:[],
    error:null,
    loading:true
}
export const addToWishlist = createAsyncThunk("wishlist/add",async(params,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await usePostDataToken('/wishlists',params)
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
})
export const deleteFromWishlist = createAsyncThunk("wishlist/delete",async(id,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useDeleteData(`/wishlists/${id}`)
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
})
export const getAllWishlist = createAsyncThunk("wishlist/all",async(id,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetDataToken("/wishlists")
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
})

const WishlistSlice = createSlice({
    name:"WishlistSlice",
    initialState,
    extraReducers:(builder)=>{
        builder
        // get all
        .addCase(getAllWishlist.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(getAllWishlist.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.allWishlist = action?.payload
        })
        .addCase(getAllWishlist.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.allWishlist = action?.payload
        })
        // add
        .addCase(addToWishlist.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(addToWishlist.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.addToWishlist = action?.payload
        })
        .addCase(addToWishlist.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.addToWishlist = action?.payload
        })
        // delete
        .addCase(deleteFromWishlist.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(deleteFromWishlist.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.deleteFromWishlist = action?.payload
        })
        .addCase(deleteFromWishlist.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.deleteFromWishlist = action?.payload
        })
    }
})
export const wishlistReducer = WishlistSlice.reducer
export const wishlistActions = WishlistSlice.actions