import React from 'react'
import './UserNotificationsPage.css'
import UserDashboardBread from '../../../components/User/UserDashboardBread/UserDashboardBread'
import UserDashboardNav from '../../../components/User/UserDashboardNav/UserDashboardNav'
import UserProfileSidebar from '../../../components/User/UserProfileSidebar/UserProfileSidebar'
import UserNotificationsContent from '../../../components/User/UserNotifications/UserNotificationsContent'
import Cookies from "js-cookie"
import { useTranslation } from 'react-i18next'
import {Helmet} from "react-helmet"

const UserNotificationsPage = () => {
  const lang = Cookies.get("i18next")
  const [t] = useTranslation()
  return (
    <div className="user-notifications-page user-profile-page page">
      <Helmet>
      <meta charSet="utf-8" />
        <title>notifications</title>
        <meta name="description" content="SIG user notifications" />
        <meta name="keywords" content="SIG Home,apartments,apartment,villas,duplexes,duplex,rent,sale,real estate,about SIG,units,real estate units" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className={`d-none d-lg-block ${lang==="en"?"user-sidebar-wrap":"user-sidebar-wrap-ar"}`}>
        <UserProfileSidebar notificationsPage={"notificationsPage"} />
      </div>
      <div className="user-dashboard">
        <UserDashboardNav notificationsPage={"page-active"} />
        <UserDashboardBread section_title={t("user.userNotificationsPage.title")} />
        <div className="dashboard-main-content">
        <UserNotificationsContent />
        </div>
      </div>
    </div>
  )
}

export default UserNotificationsPage
