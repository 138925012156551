import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import BtnComponent from "../../Utility/BtnComponent/BtnComponent";
import {faPhone,faUpload,faLocationDot,faUser,faEnvelope,faBlog,faBuilding,faLink,
} from "@fortawesome/free-solid-svg-icons";
import {faFacebook,faInstagram,faLinkedin,faTwitter,faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import UserSettingHook from "../../../CustomHooks/User/UserSettingHook";
import { Skeleton } from "@mui/material";
import anonymous from '../../../images/anonymous.png'
import { useTranslation } from "react-i18next";
const UserSettingInfoSection = () => {
   const [img,name,email,phone,company_name,website_link,city,address,bio,facebook,whatsapp,twitter
        ,instagram,linkedin,onChangeImg,onChangeName,onChangeEmail,onChangePhone,onChange_companyName,
        onChange_websiteLink,onChangeBio,onChange_facebook,onChange_twitter,onChange_whatsapp,
        onChange_linkedin,onChange_instagram,onChangeCity,onChangeAddress,onSubmit,loading,
        isPress,info_loading
    ]= UserSettingHook();
  // input skelton
  const InputSkeleton = ()=>{
    return(
        <>
         <Skeleton variant="rounded" height={20} sx={{width:"50%",margin:"3px 0"}} />
        <Skeleton variant="rounded" height={50} sx={{width:"100%"}} />
        </>
    )
  }
  const [t] = useTranslation()
  return (
    <div className="user-setting-info-section form-box form-box-1">
      <div className="form-title-wrap">
        <h3 className="title">{t("user.userSettingPage.personalInformation")}</h3>
      </div>
      <div className="form-content">
        <div className="user-profile-action d-flex align-items-center pb-4">
          <div className="form-content-head d-flex justify-content-between">
            <div className="img-con">
                {
                    info_loading === true ?
                    <Skeleton variant="circular" height={80} width={80} sx={{margin:"5px 10px"}} />
                    :
                    <div className="user-img">
                    <img src={img ||anonymous } alt="profile-img" />
                    </div>
                }
            </div>
            <div className="upload-btn-box">
              <p className="pb-2 font-size-15 line-height-24">
              {t("user.userSettingPage.uploadImgInstruction")}
              </p>
              <div className="file-upload-wrap file-upload-wrap-2">
                <label htmlFor="upload-image">
                  <FontAwesomeIcon icon={faUpload} />
                  {t("user.userSettingPage.uploadPhoto")}
                </label>
                <input
                  id="upload-image"
                  type="file"
                  name="files"
                  value={""}
                  onChange={onChangeImg}
                  className="multi file-upload-input with-preview d-none"
                  maxLength={"1"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="contact-form-action">
          <form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-12 col-sm-6 col-md-4">
                  <div className="input-box">
                    {
                    info_loading === true ?
                    <InputSkeleton />
                    :
                    <>
                    <label className="label-text"> {t("user.userSettingPage.name")}</label>
                    <div className="form-group">
                      <FontAwesomeIcon icon={faUser} />
                      <input
                        value={name}
                        onChange={onChangeName}
                        minLength={4}
                        maxLength={20}
                        name="name"
                        placeholder={t("user.userSettingPage.name")}
                        className="custom-input"
                        type="text"
                      />
                    </div>
                    </>
                    }
                  </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <div className="input-box">
                    {
                        info_loading === true ?
                        <InputSkeleton />
                        :
                        <>
                  <label className="label-text">{t("user.userSettingPage.email")}</label>
                  <div className="form-group">
                    <FontAwesomeIcon icon={faEnvelope} />
                    <input
                      value={email}
                      onChange={onChangeEmail}
                      name="email"
                      placeholder={t("user.userSettingPage.email")}
                      className="custom-input"
                      type="email"
                    />
                  </div>
                        </>
                    }
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <div className="input-box">
                    {
                        info_loading === true ?
                        <InputSkeleton />
                        :
                        <>
                  <label className="label-text">{t("user.userSettingPage.phone")}</label>
                  <div className="form-group">
                    <FontAwesomeIcon icon={faPhone} />
                    <input
                      className="custom-input"
                      value={phone}
                      onChange={onChangePhone}
                      type="tel"
                      pattern="01[0-2]\d{8,9}"
                      minLength={11}
                      maxLength={11}
                      name="phone"
                      placeholder={t("user.userSettingPage.phone")}
                    />
                  </div>
                        </>
                        
                    }
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <div className="input-box">
                    {
                        info_loading === true ?
                        <InputSkeleton />
                        :
                        <>
                  <label className="label-text">{t("user.userSettingPage.city")}</label>
                  <div className="form-group">
                    <FontAwesomeIcon icon={faLocationDot} />
                    <input
                      value={city}
                      onChange={onChangeCity}
                      placeholder={t("user.userSettingPage.city")}
                      name="city"
                      className="custom-input"
                      type="text"
                    />
                  </div>
                        </>
                    }
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <div className="input-box">
                    {
                        info_loading === true ?
                        <InputSkeleton />
                        :
                        <>
                  <label className="label-text">{t("user.userSettingPage.address")}</label>
                  <div className="form-group">
                    <FontAwesomeIcon icon={faLocationDot} />
                    <input
                      value={address}
                      onChange={onChangeAddress}
                      placeholder={t("user.userSettingPage.address")}
                      name="address"
                      className="custom-input"
                      type="text"
                    />
                  </div>
                        </>
                    }
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <div className="input-box">
                    {
                        info_loading === true ?
                        <InputSkeleton />
                        :
                        <>
                  <label className="label-text">{t("user.userSettingPage.bio")}</label>
                  <div className="form-group">
                    <FontAwesomeIcon icon={faBlog} />
                    <input
                      value={bio}
                      onChange={onChangeBio}
                      placeholder={t("user.userSettingPage.bio")}
                      name="bio"
                      className="custom-input"
                      type="text"
                    />
                  </div>
                        </>
                    }
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <div className="input-box">
                    {
                        info_loading === true ?
                        <InputSkeleton />
                        :
                        <>
                  <label className="label-text">{t("user.userSettingPage.companyName")}</label>
                  <div className="form-group">
                    <FontAwesomeIcon icon={faBuilding} />
                    <input
                      value={company_name}
                      onChange={onChange_companyName}
                      placeholder={t("user.userSettingPage.companyName")}
                      name="company name"
                      className="custom-input"
                      type="text"
                    />
                  </div>
                        </>
                    }
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <div className="input-box">
                    {
                        info_loading === true ?
                        <InputSkeleton />
                        :
                        <>
                  <label className="label-text">{t("user.userSettingPage.websiteLink")}</label>
                  <div className="form-group">
                    <FontAwesomeIcon icon={faLink} />
                    <input
                      value={website_link}
                      onChange={onChange_websiteLink}
                      placeholder={t("user.userSettingPage.websiteLink")}
                      name="website"
                      className="custom-input"
                      type="text"
                    />
                  </div>
                        </>
                    }
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <div className="input-box">
                    {
                        info_loading === true ?
                        <InputSkeleton />
                        :
                        <>
                  <label className="label-text">{t("user.userSettingPage.whatsapp")}</label>
                  <div className="form-group">
                    <FontAwesomeIcon icon={faWhatsapp} />
                    <input
                      className="custom-input"
                      value={whatsapp}
                      onChange={onChange_whatsapp}
                      name="whatsapp"
                      placeholder={t("user.userSettingPage.whatsapp")}
                      type="tel"
                      pattern="01[0-2]\d{8,9}"
                      minLength={11}
                      maxLength={11}
                    />
                  </div>
                        </>
                    }
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <div className="input-box">
                    {
                        info_loading === true ?
                        <InputSkeleton />
                        :
                        <>
                  <label className="label-text">{t("user.userSettingPage.facebook")}</label>
                  <div className="form-group">
                    <FontAwesomeIcon icon={faFacebook} />
                    <input
                      value={facebook}
                      onChange={onChange_facebook}
                      name="facebook"
                      placeholder={t("user.userSettingPage.facebook")}
                      className="custom-input"
                      type="text"
                    />
                  </div>
                        </>
                    }
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <div className="input-box">
                    {
                        info_loading === true ?
                        <InputSkeleton />
                        :
                        <>
                  <label className="label-text">{t("user.userSettingPage.twitter")}</label>
                  <div className="form-group">
                    <FontAwesomeIcon icon={faTwitter} />
                    <input
                      value={twitter}
                      onChange={onChange_twitter}
                      name="twitter"
                      placeholder={t("user.userSettingPage.twitter")}
                      className="custom-input"
                      type="text"
                    />
                  </div>
                        </>
                    }
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <div className="input-box">
                    {
                        info_loading === true ?
                        <InputSkeleton />
                        :
                        <>
                  <label className="label-text">{t("user.userSettingPage.instagram")}</label>
                  <div className="form-group">
                    <FontAwesomeIcon icon={faInstagram} />
                    <input
                      value={instagram}
                      onChange={onChange_instagram}
                      name="instagram"
                      placeholder={t("user.userSettingPage.instagram")}
                      className="custom-input"
                      type="text"
                    />
                  </div>
                        </>
                    }
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <div className="input-box">
                    {
                        info_loading === true ?
                        <InputSkeleton />
                        :
                        <>
                  <label className="label-text">{t("user.userSettingPage.linkedin")}</label>
                  <div className="form-group">
                    <FontAwesomeIcon icon={faLinkedin} />
                    <input
                      value={linkedin}
                      onChange={onChange_linkedin}
                      name="linkedin"
                      placeholder={t("user.userSettingPage.linkedin")}
                      className="custom-input"
                      type="text"
                    />
                  </div>
                        </>
                    }
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="btn-box">
                  <BtnComponent
                    loading={
                      isPress === true && loading === true ? true : false
                    }
                    type={"submit"}
                    value={t("user.userSettingPage.save")}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserSettingInfoSection;
