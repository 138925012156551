import React, { useState } from "react";
import "../Card/Card.css";
import { useNavigate } from "react-router-dom";
// import required modules
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Navigation } from "swiper/modules";
import CardHook from "../../../../CustomHooks/Card/CardHook";
import blackHeart from "../../../../images/heart.png";
import redHeart from "../../../../images/like.png";
import FormatNumberHook from "../../../../CustomHooks/FormatNumber/FormatNumberHook";
import ShareCard from "../../Share/ShareCard/ShareCard";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie'
// card carousel content
const HorizontalCard = ({ item, favItem }) => {
  const Navigate = useNavigate();
  const [handleClickWishlist, isFav, wishlistCount] = CardHook(item,favItem);
  const [formatNumber] = FormatNumberHook();
  const [active,setActive] = useState(false)
  const handleChangeActive = ()=>{
    setActive(!active)
  }
  const {t} = useTranslation()
  const lang = Cookies.get("i18next")
  return (
    <div className="horizontal-card item position-relative">
      <div className="card__image card__box d-flex">
        <div className="card__image-header h-250">
        {item?.for_sale === 1 && (
            <div className="ribbon text-capitalize">{t("utility.cards.card.soldOut")}</div>
          )}
          <Swiper
            style={{
              "--swiper-navigation-color": "white",
              "--swiper-navigation-size": "25px",
            }}
            navigation={true}
            modules={[Navigation]}
            className="my-card-swiper"
          >
             <SwiperSlide
              onClick={() => Navigate(`/single-details-page/:${item?.id}`)}
            >
              <div className="img-wrapper w-100 h-100 position-relative">
                <div className="overlay_2"></div>
                <img
                  loading="lazy"
                  src={item?.plan}
                  alt="unit-img"
                  className="img-fluid w100 img-transition"
                />
              </div>
            </SwiperSlide>
             <SwiperSlide  onClick={() => Navigate(`/single-details-page/:${item?.id}`)}>
                  <div className="img-wrapper w-100 h-100 position-relative">
                    <div className="overlay_2"></div>
                    <img
                      loading="lazy"
                      src={item?.plan}
                      alt="unit-img"
                      className="img-fluid w100 img-transition"
                    />
                  </div>
            </SwiperSlide>
            {item?.images?.map((img, i) => {
              return (
                <SwiperSlide
                  key={i}
                  onClick={() => Navigate(`/single-details-page/:${item?.id}`)}
                >
                  <div className="img-wrapper w-100 h-100 position-relative">
                    <div className="overlay_2"></div>
                    <img
                      loading="lazy"
                      src={img?.image}
                      alt="unit-img"
                      className="img-fluid w100 img-transition"
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <div className="info">
           {
            item?.type==="sale"?t("utility.cards.card.forSale"):t("utility.cards.card.forRent")
           }
            </div>
        </div>
        <div className="card__image-body">
          <div className="body-content" onClick={() => Navigate(`/single-details-page/:${item?.id}`)}>
            <div className="badges d-flex align-items-center justify-content-between">
              <span className="unit-category badge badge-primary text-capitalize">
              {
                  lang==="en"?
                  item?.category?.data_trans?item?.category?.data_trans[1]?.title_en:"...."
                  :
                  item?.category?.data_trans?item?.category?.data_trans[0]?.title_ar:"...."
                }
              </span>
              <div className="right-side-badges">
                <div className="unit-code">
                  <span className="mx-1 fw-bold">{t("utility.cards.card.code")}:</span>
                  <span>{item?.code}</span>
                </div>
              </div>
            </div>

            <div className="unit-info my-2">
              <h6 className="text-capitalize unit-title m-0">
              {
                  lang==="en"?
                  item?.data_trans?item?.data_trans[1]?.title_en:"...."
                  :
                   item?.data_trans?item?.data_trans[0]?.title_ar:"...."
                }
              </h6>
              <p className="text-capitalize m-0">
                <span><i className="fa fa-map-marker"></i></span>
                <span className="mx-2 location">{item?.location}</span>
              </p>
            </div>
            <div className="unit-properties d-none d-md-block">
              <ul className="list-row m-0">
                <li className="list-row-item mx-2">
                  <span>
                    <i className="fa fa-bath"></i> {item?.bathroom}
                  </span>
                </li>
                <li className="list-row-item mx-2">
                  <span>
                    <i className="fa fa-inbox"></i> {item?.rooms}
                  </span>
                </li>
                <li className="list-row-item mx-2">
                  <span> <i className="fa fa-map"></i> {item?.size} </span>
                </li>
              </ul>
            </div>
            <div className="owner-info">
              <ul className="list-row">
                <li className="list-row-item ">
                  <h6  className="unit-price m-0 p-0 fw-bold">{`${t("utility.cards.card.EGP")} ${formatNumber(item?.price)}`}</h6>
                </li>
              </ul>
            </div>
          </div>
          <div className="card-footer d-flex align-items-center justify-content-between flex-wrap">
            <ul className="list-row my-1 contacts">
              <li className="list-row-item">
                <a
                  href={`https://wa.me/+20${item?.user?.phone}`}
                  target="_blank"
                  rel="noreferrer"
                  className="btn-social whatsapp"
                >
                  <i className="fa fa-whatsapp"></i>
                </a>
              </li>
              <li className="list-row-item">
                <a
                  href={`tel:${item?.user?.phone}`}
                  target="_blank"
                  rel="noreferrer"
                  className="btn-social twitter"
                >
                  <i className="fa fa-phone"></i>
                </a>
              </li>
              <li className="list-row-item">
              <button className="btn-social share" onClick={()=>handleChangeActive()}>
                <i className="fa fa-share"></i>
                </button>
              </li>
            </ul>
            <div className="analysis-wrapper d-flex align-items-center justify-content-between">
              <div
                className="wishlist"
                onClick={() => handleClickWishlist(item?.id)}
              >
                <span className="mx-1">
                  <img
                    src={isFav === true ? redHeart : blackHeart}
                    alt="like-img"
                    loading="lazy"
                  />
                </span>
                <span>{wishlistCount}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {active&&<ShareCard handleChange={handleChangeActive} shareUrl={`https://sig-realestate.com/single-details-page/:${item?.id}"`}/>}
    </div>
  );
};

export default HorizontalCard;
