import { useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllCategories } from "../../Redux/Redux-Toolkit/Slices/Categories/CategoriesSlice";
const GetAllCategoriesHook = () => {
  const [loading,setLoading] = useState(false);
  const [categories,set_categories] = useState([]);
  const res = useSelector((state) => state.rootReducer.categoriesReducer.allCategories);
  const dispatch = useDispatch();

  const getData =async () => {
    setLoading(true);
    await dispatch(GetAllCategories());
    setLoading(false);
  }
  useEffect(() => {
    if(!res?.allCategories?.data){
      getData();
    }
  },[]);

  useEffect(() => {
    try{
      if (loading === false) {
        if (res?.data?.data) {
          set_categories(res.data.data);
        }
      }
    }catch(e){}
  },[loading,res]);


  return [loading,categories];
};

export default GetAllCategoriesHook;
