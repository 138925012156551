import {useState,useEffect} from 'react'
import Cookies from 'js-cookie'

const ProtectRoutsHook = () => {
  const [user,setUser]= useState(Cookies.get("user_token"))
  const [isUser,setIsUser]=useState(false)

  useEffect(()=>{
    if(user!=null){
        setIsUser(true)
    }else{
        setIsUser(false)
    }
  },[])

  return[user,isUser]
}

export default ProtectRoutsHook