import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useGetDataToken } from "../../../../Hooks/GetDataHook";

const initialState = {
    userNotifications:[],
    error:null,
    loading:true
}
export const GetUserNotifications = createAsyncThunk("userNotifications",async(_,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetDataToken('/user/notifications')
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
}
)

const userNotificationSlice = createSlice({
    name:"userNotificationSlice",
    initialState,
    extraReducers:(builder)=>{
        builder
        .addCase(GetUserNotifications.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(GetUserNotifications.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.userNotifications =action?.payload
        })
        .addCase(GetUserNotifications.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.userNotifications =action?.payload
        })
    }
})
export const userNotificationReducer = userNotificationSlice.reducer
export const userNotificationActions = userNotificationSlice.actions