import { combineReducers } from "@reduxjs/toolkit"
import { sidebarReducer } from "../Redux-Toolkit/Slices/SidebarSlice/sidebarSlice"
import { signUpReducer } from "../Redux-Toolkit/Slices/Auth/SignUpSlice"
import { loginReducer } from "../Redux-Toolkit/Slices/Auth/LoginSlice"
import { publisherSignUpReducer } from "../Redux-Toolkit/Slices/Auth/PublisherSignUpSlice"
import { userInfoReducer } from "../Redux-Toolkit/Slices/User/UserInfoSlice"
import { settingReducer } from "../Redux-Toolkit/Slices/User/UserSettingSlice"
import { userNotificationReducer } from "../Redux-Toolkit/Slices/User/UserNotificationSlice"
import { OurInfoReducer } from "../Redux-Toolkit/Slices/OurSettings/OurInfoSlice"
import { ContactUsReducer } from "../Redux-Toolkit/Slices/OurSettings/ContactUsSlice"
import { UserUnitReducer } from "../Redux-Toolkit/Slices/User/UserUnitSlice"
import { unitsReducer } from "../Redux-Toolkit/Slices/Units/UnitsSlice"
import { projectsReducer } from "../Redux-Toolkit/Slices/Projects/ProjectsSlice"
import { agencyReducer } from "../Redux-Toolkit/Slices/Agency/AgencySlice"
import { newsReducer } from "../Redux-Toolkit/Slices/News/NewsSlice"
import { categoriesReducer } from "../Redux-Toolkit/Slices/Categories/CategoriesSlice"
import { propertiesReducer } from "../Redux-Toolkit/Slices/Properties/PropertiesSlice"
import { cityReducer } from "../Redux-Toolkit/Slices/City/CitySlice"
import { wishlistReducer } from "../Redux-Toolkit/Slices/Wishlist/WishlistSlice"
import { aboutUsReducer } from "../Redux-Toolkit/Slices/OurSettings/AboutUsSlice"
import { shareReducer } from "../Redux-Toolkit/Slices/ShareSlice/ShareSlice"
import { langReducer } from "../Redux-Toolkit/Slices/Lang/LangSlice"

export default combineReducers({
    sidebarReducer,
    shareReducer,
    signUpReducer,
    loginReducer,
    publisherSignUpReducer,
    userInfoReducer,
    settingReducer,
    userNotificationReducer,
    ContactUsReducer,
    OurInfoReducer,
    aboutUsReducer,
    UserUnitReducer,
    unitsReducer,
    projectsReducer,
    agencyReducer,
    newsReducer,
    categoriesReducer,
    propertiesReducer,
    cityReducer,
    wishlistReducer,
    langReducer
})