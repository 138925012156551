import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Notify from "../UseNotification/UseNotification";
import Cookies from "js-cookie";
import { login } from "../../Redux/Redux-Toolkit/Slices/Auth/LoginSlice";

const LoginHook = () => {
  const dispatch = useDispatch();
  const response = useSelector((state) => state.rootReducer.loginReducer);
  const [loginValue, setLoginValue] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setloading] = useState(true);
  const [isPress, setIsPress] = useState(false);
  // function for states
  const onChangeLoginValue = (e) => {
    setLoginValue(e.target.value);
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };
  // on submit fn
  const onSubmit = async (e) => {
    e.preventDefault();
    setIsPress(true);
    setloading(true);
    await dispatch(
      login({
        login:loginValue,
        password,
      })
    );
    setloading(false);
    setIsPress(false);
  };
  useEffect(() => {
    if (loading === false) {
      if (response) {
        console.log(response)
        if (response?.login?.status === 200) {
          if (response?.login?.data?.token && response?.login?.data?.user) {
            Cookies.set("user",JSON.stringify(response?.login?.data?.user), {
              expires:29,
              path:"/",
              secure: true,
              // domain:"localhost"
            });
            Cookies.set("user_token",response?.login?.data?.token, {
              expires:29,
              path:"/",
              secure: true,
              // domain:"localhost"
            });
          } else {
            Cookies.remove("user");
            Cookies.remove("user_token");
          }
          Notify(response?.login?.data?.message, "success");
          setLoginValue("");
          setPassword("");
          setTimeout(() => {
            window.location.href = "/"
          }, 1000);
        } else if (response?.login?.response?.status === 400) {
          Notify(response?.login?.response?.data?.error, "error");
        } else {
          Notify("there is a problem", "error");
        }
      }
    }
  }, [loading]);
  return [
    loginValue,
    password,
    onChangeLoginValue,
    onChangePassword,
    onSubmit,
    loading,
    isPress,
  ];
};

export default LoginHook;
