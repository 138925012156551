import React from 'react'
import TopBar from './TopBar/TopBar'
import InternalNavbar from '../../Utility/Navbar/InternalNavbar/InternalNavbar'
const InternalHeader = ({homeActive,listsActive,contactUsActive,aboutUsActive,projectsActive}) => {
  return (
    <header className='internal-header'>
        <TopBar />
        <InternalNavbar
        homeActive={homeActive}
        listsActive={listsActive}
        aboutUsActive={aboutUsActive}
        contactUsActive={contactUsActive}
        projectsActive={projectsActive}
        />
    </header>
  )
}
export default InternalHeader
