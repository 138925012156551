import React from 'react'
import './UserDashboardBread.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
const UserDashboardBread = ({section_title}) => {
    const [t] = useTranslation()
  return (
    <div className="dashboard-bread dashboard">
    <div className="container-fluid h-100">
        <div className="row align-items-center h-100">
            <div className="col-12 col-md-6">
                <div className="breadcrumb-content">
                    <div className="section-heading">
                        <h2 className="sec__title text-white text-capitalize">{section_title}</h2>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="breadcrumb-list text-start">
                    <ul className="list-items m-0">
                        <li><Link to="/" className="text-white">
                            {t("user.userDashboardBread.home")}
                            </Link></li>
                            <li>.</li>
                        <li>{section_title}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default UserDashboardBread