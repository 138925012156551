import React from "react";
import "./UserProfileSidebar.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faBell, faGear, faHeart, faOutdent, faUser } from "@fortawesome/free-solid-svg-icons";
import anonymous from '../../../images/anonymous.png'
import { Skeleton } from "@mui/material";
import UserProfileHook from "../../../CustomHooks/User/UserProfileHook";
import LogoutHook from "../../../CustomHooks/Auth/LogoutHook";
import { useTranslation } from "react-i18next";
const UserProfileSidebar = ({
  profilePage,wishlistPage,
  settingPage,logout,notificationsPage
}) => {
  const [user,,] = UserProfileHook();
  const [,onLogout] = LogoutHook()
  const [t] = useTranslation()
  return (
    <div className="user-profile-sidebar">
      <div className="sidebar-nav">
        <div className="sidebar-nav-body">
          <div className="author-content">
            <div className="d-flex align-items-center">
              <div className="author-img avatar-sm">
                {
                  user !==" " ?
                  <img src={user?.image || anonymous} alt={"profile_img"} loading="lazy" />
                  :
                  <Skeleton variant="circular" width={50} height={50} sx={{ margin:'0px 5px'}}/>
                }
              </div>
              <div className="author-bio">
                <div>
                  {
                 user !==" " ?
                   <h4 className="author__title text-capitalize">{user?.name}</h4>
                   :
                   <Skeleton variant="rounded" width={120} height={20} sx={{ margin:'5px 0'}}/>
                    }
                </div>
              </div>
            </div>
          </div>
          <div className="sidebar-menu-wrap">
            <ul className="sidebar-menu d-flex flex-column">
              <Link
                to="/user-profile-page"
                className={`sidebar-menu-item-1 sidebar-menu-item  ${profilePage}`}
              >
                <span>
                <FontAwesomeIcon icon={faUser}/>
                </span>
                <span className="mx-4">{t("user.userProfileSidebar.profile")}</span>
              </Link>
              <Link
                to="/user-notifications-page"
                className={`sidebar-menu-item-1 sidebar-menu-item  ${notificationsPage}`}
              >
                <span>
                <FontAwesomeIcon icon={faBell}/>
                </span>
                <span className="mx-4">{t("user.userProfileSidebar.notifications")}</span>
              </Link>
              <Link
                to="/user-wishlist-page"
                className={`sidebar-menu-item-4 sidebar-menu-item ${wishlistPage}`}
              >
                 <span>
                 <FontAwesomeIcon icon={faHeart}/>
                </span>
                <span className="mx-4">{t("user.userProfileSidebar.wishlist")}</span>
              </Link>
              <Link
                to="/user-setting-page"
                className={`sidebar-menu-item-5 sidebar-menu-item ${settingPage}`}
              >
                 <span>
                 <FontAwesomeIcon icon={faGear}/>
                </span>
                <span className="mx-4">{t("user.userProfileSidebar.setting")}</span>
              </Link>
              <li
                className={`sidebar-menu-item-6 sidebar-menu-item ${logout}`}
                onClick={onLogout}
              >
               <span>
               <span>
               <FontAwesomeIcon icon={faOutdent} />
                </span>
                </span>
                <span className="mx-4">{t("user.userProfileSidebar.logout")}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfileSidebar;
