import React from "react";
import { Link } from "react-router-dom";
import './Breadcrumb.css'
import { useTranslation } from "react-i18next";
const BreadcrumbSection = ({title}) => {
  const [t] = useTranslation()
  return (
      <section className="section__breadcrumb">
        <div className="overlay"></div>
        <div className="container position-relative">
          <div className="row d-flex justify-content-center">
            <div className="col-md-8 text-center">
              <h2 className="text-capitalize text-white ">{title}</h2>
              <ul className="list-inline ">
                <li className="list-inline-item  mx-1">
                  <Link to={"/"} className="text-white">
                    {t("generalSections.breadcrumbSection.home")}
                  </Link>
                </li>
                <li className="list-inline-item mx-1">
                  <Link to={"/"} className="text-white">
                  {t("generalSections.breadcrumbSection.property")}
                  </Link>
                </li>
                <li className="list-inline-item mx-1">
                  <Link to={"/"} className="text-white">
                    {title}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
  );
};

export default BreadcrumbSection;
