import { useEffect } from 'react';
import './App.css';
import HomePage from './pages/HomePage/HomePage';
import {Routes ,Route,Navigate} from 'react-router-dom'
import SingleDetailsPage from './pages/SingleDetailsPage/SingleDetailsPage';
import ListingPage from './pages/ListingPage/ListingPage';
import LoginPage from './pages/AuthPages/LoginPage/LoginPage';
import SignUpPage from './pages/AuthPages/SignUpPage/SignUpPage';
import ForgetPassPage from './pages/AuthPages/ForgetPassPage/ForgetPassPage';
import VerifyPassCodePage from './pages/AuthPages/VerifyPassCodePage/VerifyPassCodePage';
import NewPassPage from './pages/AuthPages/NewPassPage/NewPassPage';
import ContactUsPage from './pages/ContactUsPage/ContactUsPage';
import AboutUsPage from './pages/AboutUsPage/AboutUsPage';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import UserProfilePage from './pages/User/UserProfilePage/UserProfilePage';
import UserSettingPage from './pages/User/UserSettingPage/UserSettingPage';
import UserWishlistPage from './pages/User/UserWishlistPage/UserWishlistPage';
import UserNotificationsPage from './pages/User/UserNotificationsPage/UserNotificationsPage';
import { ToastContainer } from 'react-toastify';
import OurInfoHook from './CustomHooks/OurSettings/OurInfo/OurInfoHook';
import ProjectsPage from './pages/ProjectPage/ProjectsPage';
import ProjectDetailsPage from './pages/ProjectDetailsPage/ProjectDetailsPage';
import i18n from './i18n/i18n';
import cookies from "js-cookie"
import { useTranslation } from 'react-i18next';
// import PrivateRoute from './components/PrivateRoute/PrivateRoute ';
import ProtectRoutsHook from './CustomHooks/Auth/ProtectRoutsHook';
import ClearLocalStorageOnReload from "./components/Utility/ClearLocalStorageOnReload/ClearLocalStorageOnReload"
function App() {
  const  [,] = OurInfoHook()
const {t,i18n} = useTranslation()
const lng = cookies.get("i18next") || "en";
useEffect(()=>{
  window.document.dir = i18n.dir()
},[lng])
useEffect(()=>{
  if (process.env.NODE_ENV === 'production') {
    console.log= function(){}
    console.error= function(){}
    console.debug= function(){}
    console.warn= function(){}
    console.info= function(){}
  }
},[])
// protect routs hook
const [,isUser]= ProtectRoutsHook()
  return (
    <div className="App">
        <Routes>
        {/* <Route element={<PrivateRoute auth={isUser}/>}> */}
           {/*Start user pages */}
           <Route path='/user-profile-page' element={<UserProfilePage />}/>
           <Route path='/user-notifications-page' element={<UserNotificationsPage />}/>
          <Route path='/user-setting-page' element={<UserSettingPage />}/>
          <Route path='/user-wishlist-page' element={<UserWishlistPage />}/>
          {/*End user pages */}
          {/* </Route> */}
          <Route path='/' element={<HomePage />}/>
          <Route path='/error-page' element={<ErrorPage />}/>
          <Route path='/single-details-page/:id' element={<SingleDetailsPage />}/>
          <Route path='/listing-page' element={<ListingPage />}/>
          <Route path='/contact-us-page' element={<ContactUsPage />}/>
          <Route path='/contact-us-page' element={<ContactUsPage />}/>
          <Route path='/about-us-page' element={<AboutUsPage />}/>
          <Route path='/projects-page' element={<ProjectsPage />}/>
          <Route path='/project-details-page/:id' element={<ProjectDetailsPage />}/>
          {/*Start Auth pages*/}
          <Route path='/sign-up-page' element={<SignUpPage />}/>
          <Route path='/login-page' element={<LoginPage />}/>
          <Route path='/forget-pass-page' element={<ForgetPassPage />}/>
          <Route path='/verify-pass-code-page' element={<VerifyPassCodePage />}/>
          <Route path='/new-pass-page' element={<NewPassPage />}/>
          {/*End Auth pages*/}
          {/*Start error page */}
          <Route path='*' exact={true}  element={<ErrorPage />}/>
          <Route path="*" element={<Navigate to="/" />} />
          {/*End error page */}
        </Routes>
        <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        transition="Bounce"
        />
        <ClearLocalStorageOnReload/>
    </div>
  );
}

export default App;
