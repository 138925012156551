import React from "react";
import "./ContactUsSidebar.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const ContactUsSidebar = () => {
  const response = useSelector((state) => state.rootReducer.OurInfoReducer);
  const [t] = useTranslation()
  return (
    <div className="contact-us-sidebar p-3">
      <h5 className="my-3">{t("contactUsPage.contactUsSidebar.infoLocation")}</h5>
      <div className="wrap__contact-form-office">
        <ul className="list-unstyled">
          <li>
            <span className="mx-2">
              <i className="fa fa-home"></i>
            </span>
             <span> {response?.ourInfo?.data?.address || "not available"}</span>
          </li>
          <li>
            <span className="mx-2">
              <i className="fa fa-phone"></i>
            </span>
            <span><a href="tel:+20"> {response?.ourInfo?.data?.phone || "not available"}</a></span>
          </li>
          <li>
            <span className="mx-2">
              <i className="fa fa-envelope"></i>
            </span>
            <span> <a href="mailto:"> {response?.ourInfo?.data?.email || "not available"}</a></span>
          </li>
        </ul>
        <div className="social__media">
          <h5>{t("contactUsPage.contactUsSidebar.findUs")}</h5>
          <ul className="list-row">
            <li className="list-row-item">
              <a href= {response?.ourInfo?.data?.facebook || "facebook.com"} className="btn-social facebook" rel="noreferrer" target="_blank">
                <i className="fa fa-facebook-f"></i>
              </a>
            </li>
            <li className="list-row-item">
              <a href={response?.ourInfo?.data?.twitter || "twitter.com"} className="btn-social twitter" rel="noreferrer" target="_blank" >
                <i className="fa fa-twitter"></i>
              </a>
            </li>
            <li className="list-row-item">
              <a href={response?.ourInfo?.data?.linkedin || "linkedin.com"} className="btn-social linkedin" rel="noreferrer" target="_blank">
                <i className="fa fa-linkedin"></i>
              </a>
            </li>
            <li className="list-row-item">
              <a href={response?.ourInfo?.data?.instagram || "instagram.com"} className="btn-social instagram " rel="noreferrer" target="_blank">
                <i className="fa fa-instagram"></i>
              </a>
            </li>
            <li className="list-row-item">
              <a href={response?.ourInfo?.data?.youtube || "youtube.com"} className="btn-social youtube" rel="noreferrer" target="_blank">
                <i className="fa fa-youtube"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ContactUsSidebar;
