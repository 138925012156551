import { configureStore } from '@reduxjs/toolkit';
import {thunk} from 'redux-thunk';
import loggerMiddleware from 'redux-logger';
import rootReducer from '../RootReducer/rootReducer'

// Define your initial state if needed
const initialState = {};

// Create an array of middleware
const middleware = [thunk];

// Configure the store with the rootReducer, initial state, and middleware

const Store = configureStore({
  reducer: {rootReducer},
  preloadedState: initialState,

  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(
    {serializableCheck:false}
  ), ...middleware],
  
});

export default Store;