import React from "react";
import './ErrorPage.css'
import BtnComponent from "../../components/Utility/BtnComponent/BtnComponent";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const ErrorPage = () => {
  const Navigate = useNavigate();
  const [t] = useTranslation()
  return (
    <div className="error-page page wrap-notfound full-height">
        <div className="overlay"></div>
      <div className="container h-100 position-relative text-white z-10 ">
        <div className="row align-items-center h-100">
          <div className="col mx-auto">
            <div className="notfound-page ">
              <h1>404</h1>
              <p>
                {t("errorPage.pageNotFound")}
                <br />
                <span>
                {t("errorPage.sorry")}
                </span>
                <br />
              </p>
              <div className="btn-box wd-fit m-auto" onClick={() => Navigate("/")}>
                <BtnComponent
                  value= {t("errorPage.home")}
                  icon={<i className="fa fa-reply"></i>}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
