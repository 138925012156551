import React from 'react'
import './ListContentNav.css'
import SelectComponent from "../../Utility/SelectComponent/SelectComponent"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGrip, faList } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import FilterHook from '../../../CustomHooks/FiltrationHooks/FilterHook';

const ListContentNav = ({changeActive,active}) => {
    const [t] = useTranslation()
    const sort_options = [
        { value: "price_asc", label:t("listingPage.listContent.lowTo")},
        { value: "price_desc", label:t("listingPage.listContent.highTo") },
      ];
      const [,,,,,,,,,,,,,,,,,,,,,,,,,onChangeSort,,,,,,]= FilterHook()
  return (
    <nav className="d-flex align-items-center justify-content-between flex-wrap">
    <ul className="nav nav-pills nav-pills-1 myTab d-flex align-items-center" role="tablist">
      <li className="list-inline-item mr-auto d-flex flex-nowrap align-items-center w-100">
        <span className="title-text">{t("listingPage.listContent.sortBy")}</span>
        <div className="select-wrapper">
            <SelectComponent options={sort_options} placeholder={t("listingPage.listContent.basedProperties")} onChange={onChangeSort}/>
        </div>
      </li>
    </ul>
    <div className='d-none d-sm-block'>
    <ul className="nav-pills myTab m-0" role="tablist">
      <li
        className={
          active === "vertical-cards"
            ? "nav-item transition active"
            : "nav-item transition"
        }
        onClick={() => changeActive("vertical-cards")}
      >
       <FontAwesomeIcon icon={faGrip}/>
      </li>
      <li
        className={
          active === "horizontal-cards"
            ? "nav-item transition active"
            : "nav-item transition"
        }
        onClick={() => changeActive("horizontal-cards")}
      >
        <FontAwesomeIcon icon={faList}/>
      </li>
    </ul>
    </div>
    </nav>
  )
}

export default ListContentNav
