import React from "react";
import ReadMore from "../../../Utility/ReadMore/ReadMore";
import Cookies from "js-cookie"
import { useTranslation } from "react-i18next";
const SingleDetailDesc = ({unitDetails}) => {
  const lang= Cookies.get("i18next")
  const [t] = useTranslation()
  return (
    <div className="single__detail-desc">
      <h5 className="text-capitalize detail-heading fw-bold fs-22 color-primary">{t("singleDetailsPage.singleDetailsDes.description")}</h5>
      <div className="show_more">
        {
        unitDetails?.data_trans?
        lang==="en"?
        <ReadMore des={unitDetails?.data_trans[1]?.description_en}/>
        :
        <ReadMore des={unitDetails?.data_trans[0]?.description_ar}/>
        :
        null
        }
      </div>
    </div>
  );
};

export default SingleDetailDesc;
