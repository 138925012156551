import React, { useState } from "react";
import "./UserDashboardNav.css";
import { Link } from "react-router-dom";
import logo from "../../../images/logo.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsStaggered, faOutdent, faXmark } from "@fortawesome/free-solid-svg-icons";
import LogoutHook from "../../../CustomHooks/Auth/LogoutHook";
const UserDashboardNav = ({
  profilePage,
  notificationsPage,
  wishlistPage,
  settingPage,
  logout,
}) => {
  const [showMobileM, setShowMobielM] = useState("");
  const handleMobileMenuShow = () => {
    showMobileM === "" ? setShowMobielM("show") : setShowMobielM("");
  };
  const [,onLogout] = LogoutHook()
  return (
    <nav className="user-dashboard-nav">
      <div className="dashboard-area">
        <div className="dashboard-nav">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="menu-wrapper">
                  <div className="left-side">
                    <Link to="/">
                      <div className="logo-content">
                        <img
                          className="logo w-100"
                          src={logo}
                          alt="logo"
                          loading="lazy"
                        />
                      </div>
                    </Link>
                  </div>
                  <div className="right-side">
                    <div
                      className="user-menu-open-wrapper d-block d-lg-none"
                      onClick={handleMobileMenuShow}
                    >
                      <div className="user-menu-open custom-icon">
                        <FontAwesomeIcon icon={faBarsStaggered}/>
                      </div>
                    </div>
                    <div
                      className={`mobile-menu-wrapper transition ${showMobileM}`}
                    >
                      <div
                        className="mobile-menu-wrapper-close custom-icon"
                        onClick={handleMobileMenuShow}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </div>
                      <div className="mobile-menu-wrapper-content"  onClick={handleMobileMenuShow}>
                        <div className="user-list my-4">
                          <ul className="sidebar-menu">
                            <Link
                              to="/user-profile-page"
                              className={`sidebar-menu-item-1 sidebar-menu-item  ${profilePage}`}
                            >
                              <span>
                                <i className="fa fa-user"></i>
                              </span>
                              <span>profile</span>
                            </Link>
                            <Link
                              to="/user-notifications-page"
                              className={`sidebar-menu-item-1 sidebar-menu-item  ${notificationsPage}`}
                            >
                              <span>
                                <i className="fa fa-bell"></i>
                              </span>
                              <span>notifications</span>
                            </Link>
                            <Link
                              to="/user-wishlist-page"
                              className={`sidebar-menu-item-4 sidebar-menu-item ${wishlistPage}`}
                            >
                              <span>
                                <i className="fa fa-heart"></i>
                              </span>
                              <span>wishlist</span>
                            </Link>
                            <Link
                              to="/user-setting-page"
                              className={`sidebar-menu-item-5 sidebar-menu-item ${settingPage}`}
                            >
                              <span>
                                <i className="fa fa-gear"></i>
                              </span>
                              <span>Settings</span>
                            </Link>
                            <li
                              className={`sidebar-menu-item-6 sidebar-menu-item ${logout}`}
                              onClick={onLogout}
                            >
                              <span>
                                <span>
                                  <FontAwesomeIcon icon={faOutdent} />
                                </span>
                              </span>
                              <span>logout</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default UserDashboardNav;
