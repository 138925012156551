import  { useState } from 'react'
import FormatNumberHook from '../../FormatNumber/FormatNumberHook';
import GetAllPropertiesHook from '../../PropertiesHook/GetAllPropertiesHook';
import GetAllCategoriesHook from '../../Categories/GetAllCategoriesHook';
import FilterHook from '../../FiltrationHooks/FilterHook';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie'
import GetAllCitiesHook from '../../CityHook/GetAllCitiesHook';
const ListingSidebarHook = () => {
  // translation
  const lang= Cookies.get("i18next")
   const [t] = useTranslation();
  // start for range slider
  const [value, setValue] = useState([0, 1000]);
  // react share component show
  const [active, setActive] = useState(false);
  // filter hook
  const [
    price,
    space,
    rooms,
    bathrooms,
    location,
    type,
    searchWord,
    property_id,
    category_id,
    minPrice,
    maxPrice,
    onChangePrice,
    onChangeSpace,
    onChangeBathrooms,
    onChangeLocation,
    onChangeType,
    onChangeSearchWord,
    onChangeRooms,
    onChangePropertyId,
    onChangeCategoryId,
    onChangeMaxPrice,
    onChangeMinPrice,
    onSearch,
    Reset,
    sort,
    onChangeSort,
    city_id,
    onChangeCityId,
    onChangeCode,
    code,
    RefreshData,
  ]= FilterHook();
  // end filter hook
  // hook to get the various data
  const [properties_loading, properties] = GetAllPropertiesHook();
  const [categories_loading, categories] = GetAllCategoriesHook();
  const [cities_loading, cities] = GetAllCitiesHook();
  const [formatNumber] = FormatNumberHook();
  // End for range slider
  // advanced filter
  const [open, setOpen] = useState(false);
  const [showState, setShowState] = useState("+");
  // handle change price
  const handleChange = (event, newValue) => {
    onChangeMinPrice(newValue[0]);
    onChangeMaxPrice(newValue[1]);
    setValue(newValue);
  };
  // handle collapse for advanced filter
  const handleCollaps = () => {
    setOpen(!open);
    showState === "+" ? setShowState("-") : setShowState("+");
  };
  // End advanced filter
  const handleTypeLang =()=>{
    if(lang==="en"){
      return localStorage.getItem("type")==="sale"?"sale":"rent"
    }else{
        return localStorage.getItem("type")==="sale"?"بيع":"ايجار"
    }
   
  }
  const typeList = [
    { value: "sale", label: t("listingPage.listingSidebar.sale") },
    { value: "rent", label:t("listingPage.listingSidebar.rent")  },
  ];
  const categoryList = [
    categories_loading === false
      ? categories
        ? categories?.map((item, i) => {
            return {
              id: `${item?.id}`,
              value: `${
                lang==="en"?item?.data_trans?item?.data_trans[1]?.title_en:null:item?.data_trans?item?.data_trans[0]?.title_ar:null
              }`,
              label: `${
                lang==="en"?item?.data_trans?item?.data_trans[1]?.title_en:null:item?.data_trans?item?.data_trans[0]?.title_ar:null
              }`,
            };
          })
        : null
      : null,
  ];
  const CitiesList = [
    cities_loading === false
      ? cities
        ? cities?.map((item, i) => {
            return {
              id: `${item?.id}`,
              value: `${
                lang==="en"?
                item?.data_trans?item?.data_trans[1]?.title_en:null
                :
                item?.data_trans?item?.data_trans[0]?.title_ar:null
              }`,
              label: `${
                lang==="en"?
                item?.data_trans?item?.data_trans[1]?.title_en:null
                :
                item?.data_trans?item?.data_trans[0]?.title_ar:null
              }`,
            };
          })
        : null
      : null,
  ];
  const options_3 = [
    { value: "1500", label: "1500" },
    { value: "1200", label: "1200" },
    { value: "900", label: "900" },
    { value: "600", label: "600" },
  ];
  const options_5 = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
  ];
  const options_6 = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
  ];
  // get all cities and all categories
  let categoryValueResult = "";
  let cityValueResult = "";
  const getCategoryValue = () => {
    let result1 = categoryList
      ? categoryList[0]?.filter((item) => {
          return item?.id === localStorage.getItem("category_id");
        })
      : null;
    categoryValueResult = result1 ? result1[0]?.value : "";
  };
  getCategoryValue();
  const getCityValue = () => {
    let result2 = CitiesList
      ? CitiesList[0]?.filter((item) => {
          return item?.id === localStorage.getItem("cityId");
        })
      : null;
    cityValueResult = result2 ? result2[0]?.value : "";
  };
  getCityValue();
  // change active state
  const handleChangeActive = () => {
    setActive(!active);
  };
  // End react share
 return [t,onSearch,Reset,searchWord,onChangeSearchWord,code,onChangeCode,city_id,CitiesList,cityValueResult,onChangeCityId,
    typeList,onChangeType,handleTypeLang,categoryList,onChangeCategoryId,categoryValueResult,
    options_3,onChangeSpace,options_5,onChangeRooms,options_6,onChangeBathrooms,formatNumber,
    minPrice,maxPrice,value,handleChange,open,handleCollaps,showState,properties_loading,
    properties,lang,onChangePropertyId,handleChangeActive,active,categories_loading,categories
] 
}

export default ListingSidebarHook
