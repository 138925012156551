import React from "react";
import { Link } from "react-router-dom";
import BtnComponent from "../../../components/Utility/BtnComponent/BtnComponent";
import UserProfileHook from "../../../CustomHooks/User/UserProfileHook";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
const UserProfileInfoSection = () => {
  const [user, loading, data] = UserProfileHook();
  const [t] = useTranslation()
  // skelton component for this section
  const InfoLineSkelton = () => {
    return (
      <Skeleton
        variant="rounded"
        width={"100%"}
        height={"40px"}
        sx={{ margin: "15px 0" }}
      />
    );
  };
  return (
    <div className="user-profile-info-section row-content">
      <div className="form-box">
        <div className="form-title-wrap border-bottom-0 pb-0">
          <h3 className="title text-capitalize my-3">{t("user.userProfilePage.title")}</h3>
        </div>
        <div className="form-content">
          <div className="table-form table-responsive">
            {loading === true ? (
              <>
                <InfoLineSkelton />
                <InfoLineSkelton />
                <InfoLineSkelton />
                <InfoLineSkelton />
                <InfoLineSkelton />
                <InfoLineSkelton />
                <InfoLineSkelton />
              </>
            ) : (
              <table className="table">
                <tbody>
                  <tr>
                    <td className="pl-0">
                      <div className="table-content">
                        <h3 className="title font-weight-medium">{t("user.userProfilePage.name")}</h3>
                      </div>
                    </td>
                    <td>:</td>
                    <td>{data?.name}</td>
                  </tr>
                  <tr>
                    <td className="pl-0">
                      <div className="table-content">
                        <h3 className="title font-weight-medium">{t("user.userProfilePage.email")}</h3>
                      </div>
                    </td>
                    <td>:</td>
                    <td>{data?.email}</td>
                  </tr>
                  <tr>
                    <td className="pl-0">
                      <div className="table-content">
                        <h3 className="title font-weight-medium">{t("user.userProfilePage.phone")}</h3>
                      </div>
                    </td>
                    <td>:</td>
                    <td>{data?.phone || ""}</td>
                  </tr>
                  {user?.role !== "client" ? (
                    <>
                      {user?.role !== "unit_onwer" && (
                        <>
                          <tr>
                            <td className="pl-0">
                              <div className="table-content">
                                <h3 className="title font-weight-medium">
                                {t("user.userProfilePage.companyName")}
                                </h3>
                              </div>
                            </td>
                            <td>:</td>
                            <td>{data?.company_name || ""}</td>
                          </tr>
                          <tr>
                            <td className="pl-0">
                              <div className="table-content">
                                <h3 className="title font-weight-medium">
                                {t("user.userProfilePage.commercialRegistration")}
                                </h3>
                              </div>
                            </td>
                            <td>:</td>
                            <td>{data?.commercial_registration_no || ""}</td>
                          </tr>
                        </>
                      )}
                      <tr>
                        <td className="pl-0">
                          <div className="table-content">
                            <h3 className="title font-weight-medium">
                            {t("user.userProfilePage.whatsapp")}
                            </h3>
                          </div>
                        </td>
                        <td>:</td>
                        <td>{data?.whatsapp || ""}</td>
                      </tr>
                      <tr>
                        <td className="pl-0">
                          <div className="table-content">
                            <h3 className="title font-weight-medium">  {t("user.userProfilePage.city")}</h3>
                          </div>
                        </td>
                        <td>:</td>
                        <td>{data?.city || ""}</td>
                      </tr>
                      <tr>
                        <td className="pl-0">
                          <div className="table-content">
                            <h3 className="title font-weight-medium">
                            {t("user.userProfilePage.address")}
                            </h3>
                          </div>
                        </td>
                        <td>:</td>
                        <td>{data?.address || ""}</td>
                      </tr>
                    </>
                  ) : null}
                </tbody>
              </table>
            )}
          </div>
          <div className="btn-box wd-fit mt-4">
            <Link to={"/user-setting-page"}>
              <BtnComponent value=  {t("user.userProfilePage.editProfile")} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfileInfoSection;
