import React from "react";
import AuthTitle from "../../../components/Auth/AuthTitle/AuthTitle";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const NewPassPage = () => {
  const [t] =useTranslation()
  return (
    <div className="forget-pass-page auth-page page">
      <Helmet>
      <meta charSet="utf-8" />
        <title>New Password</title>
        <meta name="description" content="SIG new password page" />
        <meta name="keywords" content="SIG Home,apartments,apartment,villas,duplexes,duplex,rent,sale,real estate,about SIG,units,real estate units" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <section className="section">
        <div className="auth-content">
          <div className="auth-page-title">
          <AuthTitle value={"SIG"}/>
          </div>
          <div className="forget-pass-card auth-card ">
            <div className="card-body">
              <h4 className="card-title mb-4">{t("authPages.newPassPage.title")}</h4>
              <form className="txt-start">
                <div className="form-group">
                  <input
                    className="custom-input"
                    placeholder={t("authPages.newPassPage.title")}
                    type="password"
                  />
                </div>
                <div className="form-group">
                  <input
                    className="custom-input"
                    placeholder={t("authPages.newPassPage.confirmPassword")}
                    type="password"
                  />
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-primary btn-block">
                  {t("authPages.newPassPage.submit")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NewPassPage;
