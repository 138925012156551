import React  from 'react';
import './Carousel.css'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination,Autoplay,Navigation } from 'swiper/modules';
import Card from '../Cards/Card/Card'
import GetAllWishlistHook from '../../../CustomHooks/Wishlist/GetAllWishlistHook';
const Carousel = ({units}) => {
const [,,wishlistIds] = GetAllWishlistHook()
  
  return (
    <Swiper
        spaceBetween={10}
        navigation={true}
        style={{
          "--swiper-pagination-bullet-width": "10px",
          "--swiper-pagination-bullet-height": "10px",
          "--swiper-pagination-bottom": "-4px",
          "--swiper-pagination-color": "#f16022",
          "--swiper-navigation-color": "black",
          "--swiper-button-prev": "black",
          "--swiper-navigation-size": "35px",
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter:true,
          waitForTransition:true,
        }}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          '@0.00': {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          '@0.75': {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          '@1.00': {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        }}
        modules={[Pagination,Autoplay,Navigation]}
        className="mySwiper3"
      >
        {
          units?.map((item,i)=>{
            return(
              <SwiperSlide key={item?.id}>
              <Card item={item} favItem={wishlistIds}/>
              </SwiperSlide>
            )
          })
        }
    </Swiper>
  )
}

export default Carousel
