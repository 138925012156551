import React from "react";
import "./ListingSidebar.css";
import BtnComponent from "../../../components/Utility/BtnComponent/BtnComponent";
import { Collapse } from "react-bootstrap";
// import element of material ui {range slider}
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import { Skeleton } from "@mui/material";
import SelectComponent from "../../Utility/SelectComponent/SelectComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ShareComponent from "../../Utility/Share/ShareComponent/ShareComponent";
import ListingSidebarHook from "../../../CustomHooks/Listing/ListingSidebarHook/ListingSidebarHook";
const ListingSidebar = () => {
 const [t,onSearch,Reset,searchWord,onChangeSearchWord,code,onChangeCode,city_id,CitiesList,cityValueResult,onChangeCityId,
  typeList,onChangeType,handleTypeLang,categoryList,onChangeCategoryId,categoryValueResult,
  options_3,onChangeSpace,options_5,onChangeRooms,options_6,onChangeBathrooms,formatNumber,
  minPrice,maxPrice,value,handleChange,open,handleCollaps,showState,properties_loading,
  properties,lang,onChangePropertyId,handleChangeActive,active,categories_loading,categories
] = ListingSidebarHook()
  return (
    <div className="listing-sidebar">
      <div className="products__filter mb-30">
        <div className="products__filter__group">
          <div className="products__filter__header">
            <h5 className="text-capitalize fw-bold">
              {t("listingPage.listingSidebar.title")}
            </h5>
          </div>
          <div className="products__filter__body my-3">
            <div className="form-group">
              <form onSubmit={onSearch}>
                <div className="row no-gutters my-2">
                  <div className="col-8">
                    <input
                      className="custom-input"
                      value={searchWord}
                      onChange={onChangeSearchWord}
                      type="search"
                      placeholder={t("listingPage.listingSidebar.search")}
                    />
                  </div>
                  <div className="col-4">
                    <BtnComponent
                      icon={<i className="fa fa-search"></i>}
                      type={"submit"}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="form-group">
              <input
                className="custom-input"
                value={code}
                onChange={onChangeCode}
                type="search"
                placeholder={t("listingPage.listingSidebar.typeCode")}
              />
            </div>
            <div className="form-group">
              <SelectComponent
                options={typeList}
                placeholder={t("listingPage.listingSidebar.type")}
                onChange={onChangeType}
                value={{
                  value: localStorage.getItem("type"),
                  label:handleTypeLang() ||
                    t("listingPage.listingSidebar.type"),
                }}
              />
            </div>
            <div className="form-group">
              <SelectComponent
                options={categoryList[0]}
                placeholder={t("listingPage.listingSidebar.category")}
                onChange={onChangeCategoryId}
                value={{
                  value: categoryValueResult,
                  label:
                    categoryValueResult ||
                    t("listingPage.listingSidebar.category"),
                }}
              />
            </div>
            <div className="form-group">
              <SelectComponent
                options={options_3}
                placeholder={t("listingPage.listingSidebar.area")}
                onChange={onChangeSpace}
                value={{
                  value: localStorage.getItem("space"),
                  label:
                    localStorage.getItem("space") ||
                    t("listingPage.listingSidebar.area"),
                }}
              />
            </div>
            <div className="form-group">
              <SelectComponent
                options={CitiesList[0]}
                placeholder={t("listingPage.listingSidebar.cityName")}
                onChange={onChangeCityId}
                value={{
                  value: cityValueResult,
                  label:
                    cityValueResult || t("listingPage.listingSidebar.cityName"),
                }}
              />
            </div>
            <div className="form-group">
              <SelectComponent
                options={options_5}
                placeholder={t("listingPage.listingSidebar.rooms")}
                onChange={onChangeRooms}
                value={{
                  value: localStorage.getItem("rooms"),
                  label:
                    localStorage.getItem("rooms") ||
                    t("listingPage.listingSidebar.rooms"),
                }}
              />
            </div>
            <div className="form-group">
              <SelectComponent
                options={options_6}
                placeholder={t("listingPage.listingSidebar.bathrooms")}
                onChange={onChangeBathrooms}
                value={{
                  value: localStorage.getItem("bathRooms"),
                  label:
                    localStorage.getItem("bathRooms") ||
                    t("listingPage.listingSidebar.bathrooms"),
                }}
              />
            </div>
            <div className="form-group price-range-slider">
              <Box sx={{ width: "100%" }}>
                <Typography id="track-inverted-slider" gutterBottom>
                  {t("listingPage.listingSidebar.priceRange")}
                  <span className="parent-span">
                    <span className="value-0 d-block">{`${t(
                      "listingPage.listingSidebar.EGP"
                    )} ${formatNumber(minPrice)}`}</span>
                    <span>--</span>
                    <span className="value-1">{`${t(
                      "listingPage.listingSidebar.EGP"
                    )} ${formatNumber(maxPrice)}`}</span>
                  </span>
                </Typography>
                <Slider
                  value={value}
                  onChange={handleChange}
                  min={0}
                  max={50000000}
                  step={100000}
                />
              </Box>
            </div>
            <div className="form-group mb-0 mt-2">
              <div
                className="btn-box fw-bold my-3 show-more"
                aria-controls="example-collapse-text"
                aria-expanded={open}
                onClick={handleCollaps}
              >
                <BtnComponent
                  value={`${t(
                    "listingPage.listingSidebar.advancedFilter"
                  )} ${showState}`}
                  st={"w-100"}
                />
              </div>
              <Collapse in={open}>
                <div id="example-collapse-text">
                  {properties_loading === false ? (
                    properties?.map((item, i) => {
                      return (
                        <label
                          key={item?.id}
                          className="custom-checkbox text-capitalize"
                        >
                          {lang === "en"
                            ? item?.data_trans
                              ? item?.data_trans[0]?.title_en
                              : null
                            : item?.data_trans
                            ? item?.data_trans[1]?.title_ar
                            : null}
                          <input
                            type="checkbox"
                            value={
                              item?.data_trans
                                ? item?.data_trans[0]?.title_en
                                : null
                            }
                            id={item?.id}
                            onChange={onChangePropertyId}
                          />
                          <span className="checkmark"></span>
                        </label>
                      );
                    })
                  ) : (
                    <>
                      <Skeleton
                        variant="rounded"
                        width={150}
                        height={30}
                        sx={{ margin: "5px 0" }}
                      />
                      <Skeleton
                        variant="rounded"
                        width={150}
                        height={30}
                        sx={{ margin: "5px 0" }}
                      />
                      <Skeleton
                        variant="rounded"
                        width={150}
                        height={30}
                        sx={{ margin: "5px 0" }}
                      />
                    </>
                  )}
                </div>
              </Collapse>
            </div>
          </div>
          <div className="products__filter__footer">
            <div className="form-group mb-0" onClick={onSearch}>
              <BtnComponent
                value={t("listingPage.listingSidebar.search")}
                icon={<FontAwesomeIcon icon={faSearch} />}
              />
            </div>
            <div className="form-group mb-0" onClick={Reset}>
              <BtnComponent value={t("listingPage.listingSidebar.reset")} />
            </div>
          </div>
        </div>
      </div>
      <div className=" wrapper__list__category my-5">
        <div className="widget widget__archive">
          <div className="widget__title">
            <h5 className="text-dark mb-0">
              {t("listingPage.listingSidebar.categoriesProperty")}
            </h5>
          </div>
          <ul className="list-unstyled">
            {categories_loading === false ? (
              categories?.map((item, i) => {
                return (
                  <li
                    className="categories-list-item"
                    id={item?.id}
                    key={item?.id}
                    onClick={onChangeCategoryId}
                  >
                     <span>
                        { lang==="en"?item?.data_trans?item?.data_trans[1]?.title_en:null:item?.data_trans?item?.data_trans[0]?.title_ar:null}
                      </span>
                    <span className="badge badge-primary mx-1">
                      {item?.count_unit}
                    </span>
                  </li>
                );
              })
            ) : (
              <>
                <Skeleton
                  variant="rounded"
                  width={150}
                  height={30}
                  sx={{ margin: "5px 0" }}
                />
                <Skeleton
                  variant="rounded"
                  width={150}
                  height={30}
                  sx={{ margin: "5px 0" }}
                />
                <Skeleton
                  variant="rounded"
                  width={150}
                  height={30}
                  sx={{ margin: "5px 0" }}
                />
              </>
            )}
          </ul>
        </div>
      </div>
      <div className="download mb-0">
        <div
          className="download__item my-2 rounded"
          onClick={() => handleChangeActive()}
        >
          <span role="button">
            <i className="fa fa-share mr-3 mx-1" aria-hidden="true"></i>
            {t("listingPage.listingSidebar.share")}
          </span>
        </div>
      </div>
      {active && (
        <ShareComponent
          handleChange={handleChangeActive}
          shareUrl={window.location.href}
        />
      )}
    </div>
  );
};

export default ListingSidebar;
