import React from "react";
import "./UserProfilePage.css";
import UserProfileSidebar from "../../../components/User/UserProfileSidebar/UserProfileSidebar";
import UserDashboardNav from "../../../components/User/UserDashboardNav/UserDashboardNav";
import UserDashboardBread from "../../../components/User/UserDashboardBread/UserDashboardBread";
import UserProfileInfoSection from "../../../components/User/UserProfile/UserProfileInfoSection";
import Cookies from "js-cookie"
import { useTranslation } from "react-i18next";
import {Helmet} from "react-helmet"
const UserProfilePage = () => {
  const lang = Cookies.get("i18next")
  const [t] = useTranslation()
  return (
    <div className="user-profile-page page">
       <Helmet>
      <meta charSet="utf-8" />
        <title>profile</title>
        <meta name="description" content="SIG user profile page" />
        <meta name="keywords" content="SIG Home,apartments,apartment,villas,duplexes,duplex,rent,sale,real estate,about SIG,units,real estate units" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className={`d-none d-lg-block ${lang==="en"?"user-sidebar-wrap":"user-sidebar-wrap-ar"}`}>
        <UserProfileSidebar profilePage={"profilePage"} />
      </div>
      <div className="user-dashboard">
        <UserDashboardNav profilePageActive={"page-active"} />
        <UserDashboardBread section_title={t("user.userProfilePage.title")} />
        <div className="dashboard-main-content">
         <UserProfileInfoSection />
        </div>
      </div>
    </div>
  );
};

export default UserProfilePage;
