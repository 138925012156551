import React from "react";
import "./TopBar.css";
import { Link } from "react-router-dom";
import profile_img from "../../../../images/anonymous.png";
import UserProfileHook from "../../../../CustomHooks/User/UserProfileHook";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie"
const TopBar = () => {
  const [user,,data] = UserProfileHook()
  const {t,i18n} = useTranslation()
  const lang= Cookies.get("i18next")
  const handleChangeLang = ()=>{
    window.location.href = "/"
    i18n.changeLanguage(lang==="ar"?"en":"ar")
  }
  return (
    <div className="topbar d-none d-sm-block">
      <div className="container ">
        <div className="row">
          <div className="col-6">
            <div className="topbar-left">
              <div className="topbar-text">
                <Link to="/about-us-page" className=" text-white fs-16 text-capitalize">
                {t("generalSections.topBar.aboutUs")}
                </Link>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="list-unstyled d-flex align-items-center justify-content-end h-100 topbar-right">
              <ul className="topbar-link">
                <li>
                  <Link to="/contact-us-page" title="">
                  {t("generalSections.topBar.contactUs")}
                  </Link>
                </li>
                <li>
                 <span
                 className="lang-span"
                 role="button" onClick={()=>handleChangeLang()}>
                  {lang==="ar"?"en":"ar"}
                  </span>
                </li>
                {
                  typeof(user) ==="undefined"?
                  <>
                <li>
                  <Link to="/login-page">
                  {t("generalSections.topBar.login")}
                  </Link>
                </li>
                <li>
                  <Link to="/sign-up-page" title="">
                  {t("generalSections.topBar.signUp")}
                  </Link>
                </li>
                  </>
                  :
                  <li className="mx-2 profile-btn-wrapper">
                  <Link to="/user-profile-page">
                    <div className="img-box">
                      <img
                        className="h-100 w-100"
                        src={data?.image || profile_img}
                        alt="profile-img"
                        loading="lazy"
                      />
                    </div>
                  </Link>
                </li>
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
