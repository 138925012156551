import React from "react";
import ScrollToTopBtn from "../../components/Utility/ScrollToTopBtn/ScrollToTopBtn";
import Footer from "../../components/GeneralSections/Footer/Footer";
import InternalHeader from "../../components/GeneralSections/InternalHeader/InternalHeader";
import ProjectCard from "../../components/ReasEstateProject/ProjectCard/ProjectCard";
import GetAllProjectsHook from "../../CustomHooks/ProjectsHook/GetAllProjectsHook";
import ProjectCardSkeleton from "../../components/ReasEstateProject/ProjectCardSkeleton/ProjectCardSkeleton";
import GetAllCategoriesHook from "../../CustomHooks/Categories/GetAllCategoriesHook";
import { Skeleton } from "@mui/material";
import CustomPagination from "../../components/Utility/CustomPagination/CustomPagination";
import { useNavigate } from "react-router-dom";
import BreadcrumbSection from "../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection"
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie"
import {Helmet} from "react-helmet"

const ProjectsPage = () => {
  const [loading, projects, onPressPaginate] = GetAllProjectsHook();
  const [categories_loading, categories] = GetAllCategoriesHook();
  const Navigate = useNavigate();
  const lang= Cookies.get("i18next")
  const [t] = useTranslation()
  return (
    <div className="project-page page">
       <Helmet>
      <meta charSet="utf-8" />
        <title>Projects</title>
        <meta name="description" content="Projects" />
        <meta name="keywords" content="SIG Home,apartments,apartment,villas,duplexes,duplex,rent,sale,real estate,about SIG,units,real estate units" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <InternalHeader projectsActive={"projectsActive"}/>
      <BreadcrumbSection title={t("projectsPage.title")} />
      <section>
        <div className="profile__agency">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className=" wrapper__list__category ">
                  <div className="widget widget__archive">
                    <div className="widget__title">
                      <h5 className="text-dark mb-0 text-center">
                        {t("projectsPage.categories")}
                      </h5>
                    </div>
                    <ul className="list-unstyled">
                      {categories_loading === false ? (
                        categories?.map((item, i) => {
                          return (
                            <li
                              onClick={() => Navigate("/listing-page")}
                              className="categories-list-item"
                              id={item?.id}
                              key={item?.id}
                            >
                              {lang === "en"
                                ? item?.data_trans
                                  ? item?.data_trans[1]?.title_en
                                  : null
                                : item?.data_trans
                                ? item?.data_trans[0]?.title_ar
                                : null}
                              <span className="badge badge-primary mx-1">
                                {item?.count_unit}
                              </span>
                            </li>
                          );
                        })
                      ) : (
                        <div className="skeleton-wrapper p-2 m-1">
                          <Skeleton
                            variant="rounded"
                            width={200}
                            height={30}
                            sx={{ margin: "5px 0" }}
                          />
                          <Skeleton
                            variant="rounded"
                            width={200}
                            height={30}
                            sx={{ margin: "5px 0" }}
                          />
                          <Skeleton
                            variant="rounded"
                            width={200}
                            height={30}
                            sx={{ margin: "5px 0" }}
                          />
                          <Skeleton
                            variant="rounded"
                            width={200}
                            height={30}
                            sx={{ margin: "5px 0" }}
                          />
                        </div>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="row projects-wrapper no-gutters">
                  {loading === false ? (
                    projects?.data?.map((item) => {
                      return (
                        <div className="col-lg-12 mt-0 my-2" key={item?.id}>
                          <ProjectCard item={item} />
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <ProjectCardSkeleton />
                      <ProjectCardSkeleton />
                      <ProjectCardSkeleton />
                      <ProjectCardSkeleton />
                      <ProjectCardSkeleton />
                      <ProjectCardSkeleton />
                    </>
                  )}
                </div>
                <div className="pagination-content d-flex align-items-center justify-content-center">
                  <CustomPagination
                    totalPages={projects?.meta?.last_page}
                    currentPage={projects?.meta?.current_page}
                    onChange={onPressPaginate}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ScrollToTopBtn />
      <Footer />
    </div>
  );
};

export default ProjectsPage;
