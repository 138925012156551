import React from "react";
import "./Footer.css";
import logo from "../../../images/logo.jpg";
import googlePlayImg from '../../../images/Google-play.png'
import appleStoreImg from '../../../images/apple-store.jpg'
import { Link } from "react-router-dom";
import BtnComponent from '../../Utility/BtnComponent/BtnComponent'
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFacebookF,faInstagram,faLinkedin,faTwitter,faWhatsapp, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faMapMarkerAlt, faPhoneSquare } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const ourInfo = useSelector((state) => state.rootReducer.OurInfoReducer.ourInfo);
  const [t] = useTranslation()
  return (
    <footer>
      <div className="wrapper__footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="widget__footer">
                <figure>
                  <img
                    src={logo}
                    alt="company-logo"
                    className="logo-footer"
                    loading="lazy"
                  />
                </figure>
                <p>
                 {t("generalSections.footer.col_1.description")}
                </p>
                <ul className="list-unstyled mb-0 mt-3">
                  <li>
                    <b className="mx-1"><FontAwesomeIcon icon={faMapMarkerAlt}/></b>
                    <span>{ourInfo?.data?.address ||  t("generalSections.footer.col_1.notAvailable")}</span>
                  </li>
                  <li>
                    <b  className="mx-1">
                    <FontAwesomeIcon icon={faPhoneSquare}/>
                    </b>
                    <span>{ourInfo?.data?.phone ||  t("generalSections.footer.col_1.notAvailable")}</span>
                  </li>
                  <li>
                    <b  className="mx-1">
                    <FontAwesomeIcon icon={faWhatsapp}/>
                    </b>
                    <span>{ourInfo?.data?.whatsapp ||  t("generalSections.footer.col_1.notAvailable")}</span>
                  </li>
                  <li>
                    <b  className="mx-1">
                  <FontAwesomeIcon icon={faEnvelope}/>
                    </b>
                    <span>{ourInfo?.data?.email ||  t("generalSections.footer.col_1.notAvailable")}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="widget__footer">
                <h4 className="footer-title fw-bold">{t("generalSections.footer.col_2.quickLinks.title")}</h4>
                <div className="link__category-two-column">
                  <ul className="list-unstyled ">
                    <li className="list-inline-item">
                      <Link to="/about-us-page">{t("generalSections.footer.col_2.quickLinks.list.aboutUs")}</Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/projects-page">{t("generalSections.footer.col_2.quickLinks.list.projects")}</Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/listing-page">{t("generalSections.footer.col_2.quickLinks.list.units")}</Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/contact-us-page">{t("generalSections.footer.col_2.quickLinks.list.contactUs")}</Link>
                    </li>
                  </ul>
                </div>
                <div className="download-app-wrapper my-3">
                <a href="#" >
                  <img src={googlePlayImg} alt="google-play"/>
                </a>
                <a href="#" >
                  <img src={appleStoreImg} alt="apple-store"/>
                </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="widget__footer">
                <h4 className="footer-title fw-bold">{t("generalSections.footer.col_3.title")}</h4>
                <p className="mb-2">
                {t("generalSections.footer.col_3.description")}
                </p>
                  <ul className="list-row">
                    <li className="list-row-item">
                    <a href={ourInfo?.data?.facebook || "facebook.com"} className="btn-social facebook" target="_blank" rel="noreferrer">
                      <FontAwesomeIcon icon={faFacebookF}/>
                  </a>
                    </li>
                    <li className="list-row-item">
                    <a href={ourInfo?.data?.twitter || "twitter.com"} className="btn-social twitter" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faTwitter}/>
                  </a>
                    </li>
                    <li className="list-row-item">
                    <a href={ourInfo?.data?.linkedin || "linkedin.com"} className="btn-social linkedin" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faLinkedin}/>
                  </a>
                    </li>
                    <li className="list-row-item">
                    <a href={ourInfo?.data?.instagram || "instagram.com"} className="btn-social instagram " target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faInstagram}/>
                  </a>
                    </li>
                    <li className="list-row-item">
                    <a href={ourInfo?.data?.youtube || "youtube.com"} className="btn-social youtube" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faYoutube}/>
                  </a>
                    </li>
                  </ul>
                <h4 className="footer-title">{t("generalSections.footer.col_3.title_2")}</h4>
                <div className="widget__form-newsletter ">
                  <p>
                  {t("generalSections.footer.col_3.description_2")}
                  </p>
                  <div className="mt-3">
                    <form>
                    <input
                      type="text"
                      className="custom-input mb-2"
                      placeholder= {t("generalSections.footer.col_3.yourEmail")}
                      required
                    />
                    <div className="btn-box">
                      <BtnComponent type={"submit"} value={t("generalSections.footer.col_3.subscribe")} />
                    </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg__footer-bottom-v1">
        <div className="container">
          <div className="row flex-column-reverse flex-md-row">
            <div className="col-md-6">
              <span>
              {t("generalSections.footer.footerFooter.history")} -&amp; {t("generalSections.footer.footerFooter.madeBy")} <a href="http://iss-tech.com/" target="_blank" rel="noreferrer">Iss.com</a>
              </span>
            </div>
            <div className="col-md-6">
              <ul className="list-inline ">
                <li className="list-inline-item">
                  <Link to="/about-us-page">{t("generalSections.footer.footerFooter.privacy")}</Link>
                </li>
                <li className="list-inline-item mx-2">
                <Link to="/contact-us-page">{t("generalSections.footer.footerFooter.contact")}</Link>
                </li>
                <li className="list-inline-item">
                <Link to="/about-us-page">{t("generalSections.footer.footerFooter.aboutUs")}</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
