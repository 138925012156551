import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllUnits } from "../../Redux/Redux-Toolkit/Slices/Units/UnitsSlice";
const GetAllUnitsHook = () => {
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState([]);
  const [units, setUnits] = useState([]);
  const [unitsCount, setUnitsCount] = useState(1);
  const firstRender = useRef(true);
  const res = useSelector((state) => state.rootReducer.unitsReducer.allUnits);
  const dispatch = useDispatch();
  // local storage
  let savedSearchWord = localStorage.getItem("searchWord") || "";
  let savedCategoryId = localStorage.getItem("category_id") || "";
  let savedRooms = localStorage.getItem("rooms") || "";
  let savedBathRooms = localStorage.getItem("bathRooms") || "";
  let savedCityId = localStorage.getItem("cityId") || "";
  let savedType = localStorage.getItem("type") || "";
  let savedPrice = localStorage.getItem("price") || "";
  let savedMinPrice = localStorage.getItem("minPrice") || "";
  let savedMaxPrice = localStorage.getItem("maxPrice") || "";
  let savedSpace = localStorage.getItem("space") || "";
  let savedLocation = localStorage.getItem("location") || "";
  let savedSort = localStorage.getItem("sort") || "";
  let savedPropertyId = localStorage.getItem("propertyId") || "";

  const getData = async (queryString) => {
    setLoading(true);
    await dispatch(GetAllUnits(queryString));
    setLoading(false);
  };
  useEffect(() => {
    if (firstRender.current === true) {
      getData(`filter_search=${savedSearchWord}&price=${savedPrice}&price_min=${savedMinPrice}&price_max=${savedMaxPrice}
      &space=${savedSpace}&type=${savedType}&rooms=${savedRooms}&city_id=${savedCityId}&bathroom=${savedBathRooms}
      &location=${savedLocation}&property_id=${savedPropertyId}&category_id=${savedCategoryId}&sort=${savedSort}`);
      firstRender.current = false;
    }
  }, []);
  //on press function for pagination
  const onPressPaginate = (page) => {
    getData(`filter_search=${savedSearchWord}&price=${savedPrice}&price_min=${savedMinPrice}&price_max=${savedMaxPrice}
  &space=${savedSpace}&type=${savedType}&rooms=${savedRooms}&city_id=${savedCityId}&bathroom=${savedBathRooms}
  &location=${savedLocation}&property_id=${savedPropertyId}&category_id=${savedCategoryId}&page=${page}&sort=${savedSort}`);
  };

  useEffect(() => {
    try {
      if (loading === false) {
        if (res) {
          setUnits(res?.data?.data);
          setUnitsCount(res?.data?.data?.length || 0);
          setMeta(res.meta);
        }
      }
    } catch (e) {}
  }, [loading, res]);

  return [loading, units, unitsCount, meta, getData, onPressPaginate];
};

export default GetAllUnitsHook;
