import React from "react";
import { useTranslation } from "react-i18next";

const SingleDetailAmenities = ({unitDetails}) => {
  const [t] = useTranslation()
  return (
    <div className="single__detail-features single-detail-amenities txt-start">
      <h6 className="text-capitalize detail-heading fw-bold color-primary fs-22">{t("singleDetailsPage.singleDetailsFeatures.title")}</h6>
      <ul className="list-unstyled icon-checkbox">
        {
          unitDetails?.property?.map((item,i)=>{return(
            <li key={i}>{item?.data_trans?item?.data_trans[0]?.title_en:null}</li>
          )})
        }
      </ul>
    </div>
  );
};

export default SingleDetailAmenities;
