import { useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetUserInfo } from "../../Redux/Redux-Toolkit/Slices/User/UserInfoSlice";
import Cookies from "js-cookie";
const UserProfileHook = () => {
  const [loading,setLoading] = useState(false);
  const [data,setData] = useState([]);
  const res = useSelector((state) => state.rootReducer.userInfoReducer);
  const dispatch = useDispatch();
  let user;
  try{
    if (typeof Cookies.get("user") !== "undefined") {
      user = JSON.parse(Cookies.get("user"));
    }
  }catch(e){}

  const getData =async () => {
    setLoading(true);
    await dispatch(GetUserInfo());
    setLoading(false);
  }
  useEffect(() => {
    try{
      if(res?.userInfo?.length < 1){
        getData();
      }else{
        return
      }
    }catch(e){}
  },[]);

  useEffect(() => {
    try{
      if (loading === false) {
        if (res?.userInfo?.data) {
          setData(res?.userInfo?.data);
        }
      }
    }catch(e){}
  },[loading,res]);


  return [user,loading, data];
};

export default UserProfileHook;
