import React from 'react'
import notFoundEn from "../../../images/NO UNITS FOUND.png"
import notFoundAr from "../../../images/لا يوجد وحدات.png"
import Cookies from "js-cookie"
const NotFound = () => {
  const lang = Cookies.get("i18next");
  return (
    <div className="not-available">
      {
        lang==="en"?
        <img loading='lazy' src={notFoundEn} alt='not-found'/>
        :
        <img loading='lazy' src={notFoundAr} alt='لا يوجد'/>
      }
    </div>
  )
}

export default NotFound
