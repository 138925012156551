import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useGetData } from "../../../../Hooks/GetDataHook";

const initialState = {
    allProjects:[],
    popularProjects:[],
    projectDetails:[],
    error:null,
    loading:true
}
export const GetAllProjects = createAsyncThunk("allProjects",async(page,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetData(`/projects?per_page=6&page=${page}`)
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
}
)
export const GetPopularProjects = createAsyncThunk("popularProjects",async(params,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetData('/popular-project')
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
}
)

export const GetProjectDetails = createAsyncThunk("projectDetails",async(id,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetData(`/projects/${id}`)
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
}
)

const ProjectsSlice = createSlice({
    name:"projectsSlice",
    initialState,
    extraReducers:(builder)=>{
        builder
        .addCase(GetAllProjects.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(GetAllProjects.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.allProjects =action?.payload?.data
        })
        .addCase(GetAllProjects.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.allProjects =action?.payload?.data
        })
        // popular projects
        .addCase(GetPopularProjects.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(GetPopularProjects.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.popularProjects =action?.payload?.data
        })
        .addCase(GetPopularProjects.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.popularProjects =action?.payload?.data
        })
        // Project details
        .addCase(GetProjectDetails.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(GetProjectDetails.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.projectDetails =action?.payload
        })
        .addCase(GetProjectDetails.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.projectDetails =action?.payload
        })
    }
})
export const projectsReducer = ProjectsSlice.reducer
export const projectsActions = ProjectsSlice.actions