import { Skeleton } from '@mui/material'
import React from 'react'

const LandingSkeleton = () => {
  return (
    <Skeleton variant="rectangular" sx={{width:"100%", height:"calc(100vh - 135px)"}}/>
  )
}

export default LandingSkeleton
