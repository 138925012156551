import { useEffect, useRef, useState } from "react";
import { GetAllUnits } from "../../Redux/Redux-Toolkit/Slices/Units/UnitsSlice";
import { useDispatch } from "react-redux";

const FilterHook = () => {
  // local storage
  let savedCode = localStorage.getItem("code") || "";
  let savedSearchWord = localStorage.getItem("searchWord") || "";
  let savedCategoryId = localStorage.getItem("category_id") || "";
  let savedRooms = localStorage.getItem("rooms") || "";
  let savedBathRooms = localStorage.getItem("bathRooms") || "";
  let savedCityId = localStorage.getItem("cityId") || "";
  let savedType = localStorage.getItem("type") || "";
  let savedPrice = localStorage.getItem("price") || "";
  let savedMinPrice = localStorage.getItem("minPrice") || "";
  let savedMaxPrice = localStorage.getItem("maxPrice") || "";
  let savedSpace = localStorage.getItem("space") || "";
  let savedLocation = localStorage.getItem("location") || "";
  let savedSort = localStorage.getItem("sort") || "";
  let savedPropertyId = localStorage.getItem("propertyId") || "";
  // states
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [searchWord, setSearchWord] = useState(savedSearchWord);
  const [code, setCode] = useState(savedCode);
  const [price, setPrice] = useState(savedPrice);
  const [sort, setSort] = useState(savedSort);
  const [space, setSpace] = useState(savedSpace);
  const [rooms, setRooms] = useState(savedRooms);
  const [minPrice, setMinPrice] = useState(savedMinPrice);
  const [maxPrice, setMaxPrice] = useState(savedMaxPrice);
  const [bathrooms, setBathrooms] = useState(savedBathRooms);
  const [location, setLocation] = useState(savedLocation);
  const [type, setType] = useState(savedType);
  const [city_id, set_city_id] = useState(savedCityId);
  const [property_id, set_property_id] = useState(savedPropertyId);
  const [category_id, set_category_id] = useState(savedCategoryId);
  // const [units, setUnits] = useState([]);
  // previous Values of states
  const prevCategoryId = useRef(category_id);
  const prevUnitCode = useRef(code);
  const prevSpace = useRef(space);
  const prevType = useRef(type);
  const prevRooms = useRef(rooms);
  const prevCityId = useRef(city_id);
  const prevBathrooms = useRef(bathrooms);
  const prevLocation = useRef(location);
  const prevPropertyId = useRef(property_id);
  const prevSort = useRef(sort);
  const dispatch = useDispatch();

  const getFilterData = async (queryString) => {
    setLoadingFilter(true);
    await dispatch(GetAllUnits(queryString));
    setLoadingFilter(false);
  };
  const onSearch = (e) => {
    e.preventDefault();
    getFilterData(`filter_search=${savedSearchWord}&price=${savedPrice}&price_min=${savedMinPrice}&price_max=${savedMaxPrice}
      &space=${savedSpace}&type=${savedType}&rooms=${savedRooms}&city_id=${savedCityId}&bathroom=${savedBathRooms}
      &location=${savedLocation}&property_id=${savedPropertyId}&category_id=${savedCategoryId}&sort=${savedSort}&code=${savedCode}`);
  };
  const RefreshData = () => {
    getFilterData(`filter_search=${savedSearchWord}&price=${savedPrice}&price_min=${savedMinPrice}&price_max=${savedMaxPrice}
      &space=${savedSpace}&type=${savedType}&rooms=${savedRooms}&city_id=${savedCityId}&bathroom=${savedBathRooms}
      &location=${savedLocation}&property_id=${savedPropertyId}&category_id=${savedCategoryId}&sort=${savedSort}&code=${savedCode}`);
  };
  // function to reset the filter values
  const Reset = () => {
    localStorage.clear();
    getFilterData();
  };
  // get the data when any these states changed
  const isStateChanged = () => {
    if (
      prevSpace.current !== space ||
      prevType.current !== type ||
      prevRooms.current !== rooms ||
      prevCityId.current !== city_id ||
      prevBathrooms.current !== bathrooms ||
      prevLocation.current !== location ||
      prevPropertyId.current !== property_id ||
      prevSort.current !== sort
    ) {
      prevSpace.current = space;
      prevType.current = type;
      prevRooms.current = rooms;
      prevCityId.current = city_id;
      prevBathrooms.current = bathrooms;
      prevLocation.current = location;
      prevPropertyId.current = property_id;
      prevSort.current = sort;
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    if (isStateChanged() === true) {
      getFilterData(`filter_search=${savedSearchWord}&price=${savedPrice}&price_min=${savedMinPrice}&price_max=${savedMaxPrice}
        &space=${savedSpace}&type=${savedType}&rooms=${savedRooms}&city_id=${savedCityId}&bathroom=${savedBathRooms}
        &location=${savedLocation}&property_id=${savedPropertyId}&category_id=${savedCategoryId}&sort=${savedSort}&code=${savedCode}`);
    }
  }, [space, type, rooms, city_id, bathrooms, location, property_id, sort]);
  // get the data when the category changed
  useEffect(() => {
    if (prevCategoryId.current !== category_id) {
      getFilterData(`category_id=${savedCategoryId}&type=${savedType}`);
    }
    prevCategoryId.current = category_id;
  }, [category_id]);
  // get the data when the code of unit changed
  useEffect(() => {
    if (prevUnitCode.current !== code) {
      getFilterData(`code=${savedCode}`);
    }
    prevUnitCode.current = code;
  }, [code]);
  // functions that handle various user interactions and updates the corresponding values in the browser's local storage.
  const onChangeCode = (e) => {
    setCode(e.target.value);
    localStorage.setItem("code", e.target.value);
  };
  const onChangeSearchWord = (e) => {
    setSearchWord(e.target.value);
    localStorage.setItem("searchWord", e.target.value);
  };
  const onChangeMinPrice = (e) => {
    setMinPrice(e);
    localStorage.setItem("minPrice", e);
  };
  const onChangeMaxPrice = (e) => {
    setMaxPrice(e);
    localStorage.setItem("maxPrice", e);
  };
  const onChangeCityId = (e) => {
    if (e.target) {
      if(e?.target?.value){
        set_city_id(e.target.value);
        localStorage.setItem("cityId",e.target.value);
      }else if(e?.target?.id){
        set_city_id(e.target.id);
        localStorage.setItem("cityId",e.target.id);
      }else{return}
    } else {
      set_city_id(e.id);
      localStorage.setItem("cityId", e.id);
    }
  };
  const onChangeSort = (e) => {
    setSort(e.value);
    localStorage.setItem("sort", e.value);
  };
  const onChangePrice = (e) => {
    setPrice(e.value);
    localStorage.setItem("price", e.value);
  };
  const onChangeType = (e, val) => {
    if (e.target) {
      if (e.target.value === 0) {
        setType(val);
        localStorage.setItem("type", val);
      } else {
        setType(e.target.value);
        localStorage.setItem("type", e.target.value);
      }
    } else {
      setType(e.value);
      localStorage.setItem("type", e.value);
    }
  };
  const onChangeSpace = (e) => {
    setSpace(e.value);
    localStorage.setItem("space", e.value);
  };
  const onChangeBathrooms = (e) => {
    setBathrooms(e.value);
    localStorage.setItem("bathRooms", e.value);
  };
  const onChangeRooms = (e) => {
    setRooms(e.value);
    localStorage.setItem("rooms", e.value);
  };
  const onChangeLocation = (e) => {
    setLocation(e.value);
    localStorage.setItem("location", e.value);
  };
  const onChangePropertyId = (e) => {
    if (e.target.checked === true) {
      set_property_id(e.target.id);
      localStorage.setItem("propertyId", e.value);
    }
  };
  const onChangeCategoryId = (e) => {
    if (e.target) {
      set_category_id(e.target.id);
      localStorage.setItem("category_id",e.target.id);
    } else {
      set_category_id(e.id);
      localStorage.setItem("category_id",e.id);
    }
  };

  return [
    price,
    space,
    rooms,
    bathrooms,
    location,
    type,
    searchWord,
    property_id,
    category_id,
    minPrice,
    maxPrice,
    onChangePrice,
    onChangeSpace,
    onChangeBathrooms,
    onChangeLocation,
    onChangeType,
    onChangeSearchWord,
    onChangeRooms,
    onChangePropertyId,
    onChangeCategoryId,
    onChangeMaxPrice,
    onChangeMinPrice,
    onSearch,
    Reset,
    sort,
    onChangeSort,
    city_id,
    onChangeCityId,
    onChangeCode,
    code,
    RefreshData,
  ];
};

export default FilterHook;
