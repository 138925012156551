import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {useGetData} from '../../../../Hooks/GetDataHook'
const initialState = {
    aboutUsData:[],
    error:null,
    loading:true
}
export const getAboutUsInfo = createAsyncThunk("setting/about",async(params,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetData('/setting_page/about',params)
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
})
const AboutUsSlice = createSlice({
    name:"AboutUsSlice",
    initialState,
    extraReducers:(builder)=>{
        builder
        .addCase(getAboutUsInfo.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(getAboutUsInfo.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.aboutUsData = action?.payload
        })
        .addCase(getAboutUsInfo.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.aboutUsData = action?.payload
        })
    }
})
export const aboutUsReducer = AboutUsSlice.reducer
export const aboutUsActions = AboutUsSlice.actions