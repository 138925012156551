import React from "react";
import logo from "../../../images/logo.jpg";
import UserNotificationsHook from "../../../CustomHooks/User/UserNotificationsHook";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
const UserNotificationsContent = () => {
  const [, loading, data] = UserNotificationsHook();
  const [t] = useTranslation()
  const NotSkeleton = () => {
    return (
      <li className="nots-item">
        <div className="img-box">
          <Skeleton
            variant="circular"
            height={50}
            width={50}
            sx={{ margin: "0 5px" }}
          />
        </div>
        <div className="text">
          <Skeleton
            variant="h5"
            height={20}
            width={300}
            sx={{ margin: "3px 0px", width: "100%" }}
          />
          <Skeleton
            variant="h5"
            height={20}
            width={300}
            sx={{ margin: "3px 0px", width: "100%" }}
          />
        </div>
      </li>
    );
  };
  return (
    <div className="user-notifications-content row-content">
      <div className="form-box">
        <div className="form-title-wrap border-bottom-0 pb-0">
          <h3 className="title text-capitalize my-3">{t("user.userNotificationsPage.title")}</h3>
        </div>
        <div className="nots-box">
          <ul>
            {loading === true ? (
              <>
                <NotSkeleton />
                <NotSkeleton />
                <NotSkeleton />
              </>
            ) : (
              data?.map((item, i) => {
                return (
                  <li className="nots-item" key={i}>
                    <div className="img-box">
                      <img src={logo} alt="logo-img" />
                    </div>
                    <div className="text">
                      <h5>{item?.data?.item?.title}</h5>
                      <p>{item?.data?.item?.content}</p>
                    </div>
                  </li>
                );
              })
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UserNotificationsContent;
