import { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Notify from "../UseNotification/UseNotification";
import {updatePass } from "../../Redux/Redux-Toolkit/Slices/User/UserSettingSlice";
import Cookies from 'js-cookie'
const UserPassHook = () => {
  const dispatch = useDispatch();
  const response = useSelector((state) => state.rootReducer.settingReducer);
  // hook states
  const [current_password, set_current_password] = useState("");
  const [password, set_password] = useState("");
  const [password_confirmation, set_password_confirmation] = useState("");
  const [loading, setloading] = useState(true);
  const [isPress, setIsPress] = useState(false);

  // functions to update the states
   const onChange_current_pass = (e) => {
    set_current_password(e.target.value);
  };
  const onChange_password = (e) => {
    set_password(e.target.value);
  };
  const onChange_password_confirm = (e) => {
    set_password_confirmation(e.target.value);
  };
 

  // on submit fn
  const onSubmit = async (e) => {
    e.preventDefault();
    setIsPress(true);
    setloading(true);
    await dispatch(updatePass({
        current_password,
        password,
        password_confirmation
    }));
    setloading(false);
    setIsPress(false);
  };

useEffect(() => {
    if (loading === false) {
      if (response) {
        console.log(response)
       if(response?.updatePass?.data?.status === 200){
        if (response?.updatePass?.data?.token) {
            Cookies.set("user_token", response?.updatePass?.data?.token, {
              expires:365,
              path:"/",
              secure: true,
              domain:"localhost"
            });
          }
        Notify(response?.updatePass?.data?.message,"success")
       }else if(response?.updatePass?.data?.status === 404){
        Notify(response?.updatePass?.data?.error,"error")
       }else if(response?.updatePass?.response?.status===422){
        Notify(response?.updatePass?.response?.data?.message,"error")
       }
      }
    }
  },[loading,response]);
  
  return [password,current_password,password_confirmation,
    onChange_password,onChange_current_pass,onChange_password_confirm,loading,isPress,onSubmit]

};

export default UserPassHook;
