import React, { useState } from "react";
import "./ProjectDetailsPage.css";
import ReadMore from "../../components/Utility/ReadMore/ReadMore";
import Footer from "../../components/GeneralSections/Footer/Footer";
import ScrollToTopBtn from "../../components/Utility/ScrollToTopBtn/ScrollToTopBtn";
import InternalHeader from "../../components/GeneralSections/InternalHeader/InternalHeader";
import ProjectSidebar from "../../components/ReasEstateProject/ProjectSidebar/ProjectSidebar";
import GetProjectDetailsHook from "../../CustomHooks/ProjectsHook/GetProjectDetailsHook";
import { useParams } from "react-router-dom";
import ProjectCard from "../../components/ReasEstateProject/ProjectCard/ProjectCard";
import ProjectCardSkeleton from "../../components/ReasEstateProject/ProjectCardSkeleton/ProjectCardSkeleton";
import HorizontalCardSkeleton from "../../components/Utility/Cards/HorizontalCardSkeleton/HorizontalCardSkeleton";
import HorizontalCard from "../../components/Utility/Cards/HorizontalCard/HorizontalCard";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie"
import BreadcrumbSection from "../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection";
import {Helmet} from "react-helmet"
const ProjectDetailsPage = () => {
  const [active, setActive] = useState("des");
  const { id } = useParams();
  const [loading, project_details] = GetProjectDetailsHook(
    id?.slice(1,id.length)
  );
  const lang= Cookies.get("i18next")
  const [t] = useTranslation()
  return (
    <div className="agency-details-page page">
      <Helmet>
      <meta charSet="utf-8" />
        <title>Project details</title>
        <meta name="description" content="Project details" />
        <meta name="keywords" content="SIG Home,apartments,apartment,villas,duplexes,duplex,rent,sale,real estate,about SIG,units,real estate units" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <InternalHeader agencyActive={"agencyActive"}/>
      <BreadcrumbSection title= {t("projectDetailsPage.title")} />
      <section className="profile__agents">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="row no-gutters">
                <div className="col-lg-12 cards mt-0">
                  {loading === false ? (
                    <ProjectCard item={project_details} />
                  ) : (
                    <ProjectCardSkeleton />
                  )}
                </div>
              </div>
              <div className="single__detail-features tabs__custom">
                <h5 className="text-capitalize detail-heading ">
                 {t("projectDetailsPage.title")}
                </h5>
                <ul className="nav nav-pills myTab p-2 my-2" role="tablist">
                  <li
                    role="button"
                    className="nav-item"
                    onClick={() => setActive("des")}
                  >
                    <span
                      className={
                        active === "des"
                          ? "nav-link active pills-tab-one"
                          : "nav-link pills-tab-one"
                      }
                    >
                       {t("projectDetailsPage.description")}
                    </span>
                  </li>
                  <li
                    role="button"
                    className="nav-item"
                    onClick={() => setActive("listing")}
                  >
                    <span
                      className={
                        active === "listing"
                          ? "nav-link active pills-tab-two"
                          : "nav-link pills-tab-two"
                      }
                    >
                      {t("projectDetailsPage.units")}
                    </span>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className={
                      active === "des"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="pills-tab-one"
                    role="tabpanel"
                    aria-labelledby="pills-tab-one"
                  >
                    <div className="single__detail-desc">
                        {
                          lang==="en"?
                          <ReadMore des={project_details?.data_trans? project_details?.data_trans[1]?.description_en: null}/>
                          :
                          <ReadMore des={project_details?.data_trans?project_details?.data_trans[0]?.description_ar:null}/>
                        }
                    </div>
                  </div>
                  <div
                    className={
                      active === "listing"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="pills-tab-two"
                    role="tabpanel"
                    aria-labelledby="pills-tab-two"
                  >
                    <div className="row">
                      {loading === false ? (
                        project_details?.units?.map((item) => {
                          return (
                            <div className="col-lg-12" key={item?.id}>
                              <HorizontalCard item={item}/>
                            </div>
                          );
                        })
                      ) : (
                        <>
                          <HorizontalCardSkeleton />
                          <HorizontalCardSkeleton />
                          <HorizontalCardSkeleton />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <ProjectSidebar />
            </div>
          </div>
        </div>
      </section>
      <ScrollToTopBtn />
      <Footer />
    </div>
  );
};

export default ProjectDetailsPage;
