import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useGetData } from "../../../../Hooks/GetDataHook";

const initialState = {
    allUnits:[],
    similarUnits:[],
    unitDetails:[],
    error:null,
    loading:true
}
export const GetAllUnits = createAsyncThunk("allUnits",async(queryString,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetData(`/units?per_page=${12}&${queryString}`)
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
}
)
export const GetAllSimilarUnits = createAsyncThunk("allSimilarUnits",async(queryString,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetData(`/units?per_page=${12}&${queryString}`)
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
}
)
export const GetUnitDetails = createAsyncThunk("unitDetails",async(id,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetData(`/units/${id}`)
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
}
)

const UnitsSlice = createSlice({
    name:"unitsSlice",
    initialState,
    extraReducers:(builder)=>{
        builder
        .addCase(GetAllUnits.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(GetAllUnits.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.allUnits =action?.payload?.data
        })
        .addCase(GetAllUnits.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload?.data
            state.allUnits =action?.payload?.data
        })
        // similar
        .addCase(GetAllSimilarUnits.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(GetAllSimilarUnits.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.similarUnits =action?.payload?.data
        })
        .addCase(GetAllSimilarUnits.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload?.data
            state.similarUnits =action?.payload?.data
        })
        // unit details
        .addCase(GetUnitDetails.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(GetUnitDetails.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.unitDetails =action?.payload
        })
        .addCase(GetUnitDetails.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.unitDetails =action?.payload
        })
    }
})
export const unitsReducer = UnitsSlice.reducer
export const unitsActions = UnitsSlice.actions