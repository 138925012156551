import i18n from "i18next";
import {initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
.use(initReactI18next) // passes i18n down to react-i18next
.use(LanguageDetector)
.use(HttpApi)
  .init({
    fallbackLng: "en",
    detection:{
        order: ['cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
        // cache user language on
        caches: ['localStorage', 'cookie'],
    },
    backend:{
        loadPath: '/Locale/{{lng}}/{{ns}}.json',
    }
   
  });
  export default i18n